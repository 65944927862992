import ExtraDataETL from '@/components/extraDataETL/extraDataETL'

import { DATASET_TYPE } from '../datasetUpdate.constant'
import DatasetUpdateLayout from '../datasetUpdateLayout/datasetUpdateLayout'
import { UPDATE_TABLE_STEP } from './updateTable.constant'
import {
  UpdateTableProvider,
  useUpdateTableContext,
} from './updateTable.context'
import { useChangeStepUpdateTable } from './updateTable.hook'
import { UpdateABSDatasetProps, UpdateTableProps } from './updateTable.props'
import { useUpdateTableSchema } from './updateTable.validate'
import UpdateTableUploadFile from './updateTableUploadFile/updateTableUploadFile'
import UpdateTableView from './updateTableView/updateTableView'

const UpdateTableFactory = (props: UpdateTableProps) => {
  const generateProps = props

  const { currentStep } = useUpdateTableContext()

  switch (currentStep) {
    case UPDATE_TABLE_STEP.VIEW_UPDATE:
      return <UpdateTableView {...generateProps} />
    case UPDATE_TABLE_STEP.UPLOAD_FILE:
      return <UpdateTableUploadFile {...generateProps} />
    default:
      return <UpdateTableView {...generateProps} />
  }
}

const UpdateTableContainer = ({ detail }: UpdateABSDatasetProps) => {
  const { schema } = useUpdateTableSchema()
  const { handleViewUpdate } = useChangeStepUpdateTable()
  const cbSummit = () => {
    handleViewUpdate()
  }

  return (
    <DatasetUpdateLayout
      cbSubmit={cbSummit}
      datasetType={DATASET_TYPE.TABLE}
      defaultValues={{ ...detail }}
      description={detail?.description}
      etlID={detail?.etl?.id}
      extra={
        <ExtraDataETL
          editedAt={detail?.editedAt}
          status={detail?.etl?.status}
          updatedAt={detail?.updatedAt}
        />
      }
      name={detail.name}
      schema={schema}
    >
      {(props) => <UpdateTableFactory {...props} detail={detail} />}
    </DatasetUpdateLayout>
  )
}

const UpdateTableWrapCtx = ({ detail }: UpdateABSDatasetProps) => {
  if (!detail) return null

  return (
    <UpdateTableProvider>
      <UpdateTableContainer detail={detail} />
    </UpdateTableProvider>
  )
}

export default UpdateTableWrapCtx
