import { ReactNode } from 'react'

import { Separator } from '@/components/ui/separator'
import { useTrans } from '@/hooks/useTranslation'
import { cn } from '@/lib/utils'

interface IProps {
  resource?: string
  haveSubSidebar?: boolean
  title?: string
  subtitle?: string
  description?: string
  etlID?: string
  children: ReactNode
  extra?: ReactNode
}
const PageHeader = (props: IProps) => {
  const { t } = useTrans()
  const isHaveSubSidebar = props.haveSubSidebar

  const pageHeaderClass = cn('w-full h-full overflow-auto', {
    'xl:px-36': !isHaveSubSidebar,
    '[&>div]:pt-10 [&>div]:sm:pt-[40px]': isHaveSubSidebar,
  })

  return (
    <div className={pageHeaderClass}>
      <div className="container pb-20 !pt-[47px]">
        {props.extra ? (
          <div className="border rounded-md mb-4">
            <div className="p-4">
              <div className="flex justify-between">
                <p className="mb-4 text-[12px] text-text-gray">
                  {props.resource === 'dataset'
                    ? t('dataSource')
                    : props.resource === 'dataWarehouse'
                      ? t('report')
                      : ''}
                </p>
                {props.etlID && (
                  <p className="mb-4 text-[12px] text-text-gray">
                    ID: {props.etlID}
                  </p>
                )}
              </div>
              {props.title && (
                <p className="h3 text-[#475569] leading-6">{props.title}</p>
              )}
              {props.description && (
                <p className=" text-[14px] text-text-gray">
                  {props.description}
                </p>
              )}
            </div>
            {(props.title || props.description) && (
              <Separator className="my-2" />
            )}
            {props.extra && <div className="p-4 pt-3">{props.extra}</div>}
          </div>
        ) : (
          <div className="mb-6">
            {props.title && <p className="h4 text-[#475569]">{props.title}</p>}
            {props.description && (
              <p className="text-sm leading-6 mt-2">{props.description}</p>
            )}
            {props.subtitle && (
              <p className="text-sm leading-6">{props.subtitle}</p>
            )}
          </div>
        )}
        {props.children}
      </div>
    </div>
  )
}

export default PageHeader
