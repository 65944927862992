/* eslint-disable no-unused-vars */
import { CheckedState } from '@radix-ui/react-checkbox'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { MAXIMUM_SELECT_HEADER } from '@/constants/common'
import { setIncrementalRefreshSelection } from '@/features/project/projectSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

import { IIsCheckedAll } from '../datasetCreate/updateColumns/updateColumn.props'

interface TableFormFactoryCtxProps {
  isCheckedAll: IIsCheckedAll
  handleUpdateInfoTable: (tableIndex: number, info: ANY) => void
  handleUpdateDescription: (
    tableIndex: number,
    col: string,
    description: string,
  ) => void
  handleUpdateAllColumnsChecked: (
    tableIndex: number,
    checked: CheckedState,
  ) => void
  handleUpdateColumnsChecked: (
    tableIndex: number,
    colName: string,
    checked: boolean,
  ) => void
  incrementalRefresh: Record<string, boolean>
  handleUpdateIncrementalRefresh: (tableName: string, value: boolean) => void
}

export const TableFormFactoryCtx =
  React.createContext<TableFormFactoryCtxProps | null>(null)

export const countSelectedColumns = (
  columns: Array<string>,
  selectedColumns: Record<string, boolean>,
) => {
  return columns.reduce((count: number, col: string) => {
    return selectedColumns?.[col] ? count + 1 : count
  }, 0)
}

export const TableFormFactoryProvider = ({
  children,
  form,
}: {
  children: ReactNode
  form: ANY
}) => {
  const { t } = useTrans()
  const { incrementalRefreshTables } = useAppSelector((state) => state.project)

  const dispatch = useAppDispatch()

  const [isCheckedAll, setIsCheckedAll] = useState<IIsCheckedAll>({})
  const [incrementalRefresh, setIncrementalRefresh] = useState<
    Record<number, boolean>
  >({})
  const tables = form.getValues('tables')
  const normalizeName = (name: string) => {
    return name.split('/').pop()?.split('.').shift()
  }

  useEffect(() => {
    const isCheckedAll: IIsCheckedAll = {}
    const incrementalRefresh: Record<string, boolean> = {}

    if (!tables) return
    if (Array.isArray(tables) && tables.length === 0) return

    tables.forEach((table: ANY, index: number) => {
      const columns = table.columns
      const selectedColumns = table?.selectedColumns ?? {}
      const countSelectedCol = countSelectedColumns(columns, selectedColumns)
      const tableNameNormalized = normalizeName(table.name)

      isCheckedAll[index] =
        countSelectedCol === columns.length ||
        countSelectedCol === MAXIMUM_SELECT_HEADER

      if ('incrementalRefresh' in table) {
        incrementalRefresh[tableNameNormalized!] = !!table.incrementalRefresh
        table.incrementalRefresh = incrementalRefresh[tableNameNormalized!]
      }
    })

    setIsCheckedAll(isCheckedAll)
    setIncrementalRefresh(incrementalRefresh)
  }, [dispatch, tables])

  useEffect(() => {
    dispatch(setIncrementalRefreshSelection(incrementalRefresh))
  }, [dispatch, incrementalRefresh])

  const handleUpdateIncrementalRefresh = (
    tableName: string,
    value: boolean,
  ) => {
    const tables: Array<Record<string, unknown>> = form.getValues('tables')
    const table = tables.find(
      (table) => normalizeName(table.name as string) === tableName,
    )

    if (!table) return

    table.incrementalRefresh = value

    setIncrementalRefresh((prev) => ({ ...prev, [tableName]: value }))
  }

  const handleUpdateAllColumnsChecked = (
    tableIndex: number,
    checked: CheckedState,
  ) => {
    const tables = form.getValues('tables')
    const table = tables[tableIndex]

    const columns = table.columns
    const selectedColumns: Record<string, boolean> = table.selectedColumns
    const countSelectedCol = countSelectedColumns(columns, selectedColumns)

    let avlCountCheck = MAXIMUM_SELECT_HEADER - countSelectedCol
    const newSelectedColumns = columns.reduce(
      (acc: Record<string, boolean>, col: string) => {
        if (checked) {
          if (avlCountCheck > 0) {
            acc[col] = true
            avlCountCheck--
            return acc
          }
          return acc
        } else {
          acc[col] = false
          return acc
        }
      },
      {
        ...selectedColumns,
      },
    )

    tables[tableIndex].selectedColumns = newSelectedColumns
    form.setValue('tables', tables, { shouldDirty: true })
    setIsCheckedAll({ ...isCheckedAll, [tableIndex]: checked })
  }

  const handleUpdateColumnsChecked = (
    tableIndex: number,
    colName: string,
    checked: boolean,
  ) => {
    const tables = form.getValues('tables')
    const table = tables[tableIndex]

    const columns = table.columns
    const selectedColumns = table?.selectedColumns ?? {}

    const countSelectedCol = countSelectedColumns(columns, selectedColumns)
    if (
      selectedColumns[colName] === false &&
      countSelectedCol >= MAXIMUM_SELECT_HEADER
    ) {
      toast.warning(
        t('limitSelectedInputData', { limit: MAXIMUM_SELECT_HEADER }),
      )

      if (!isCheckedAll[tableIndex]) {
        isCheckedAll[tableIndex] = true
        setIsCheckedAll({ ...isCheckedAll })
      }

      return
    }

    selectedColumns[colName] = checked

    if (checked) {
      if (
        countSelectedCol >= MAXIMUM_SELECT_HEADER - 1 ||
        countSelectedCol === columns.length - 1
      ) {
        // setIsCheckedAll(true)
        isCheckedAll[tableIndex] = true
        setIsCheckedAll({ ...isCheckedAll })
        tables[tableIndex].selectedColumns = selectedColumns

        form.setValue('tables', tables, { shouldDirty: true })
        return
      }
    }

    if (
      !checked &&
      countSelectedCol - 1 < MAXIMUM_SELECT_HEADER &&
      isCheckedAll[tableIndex]
    ) {
      // checked is False
      // countSelectedCol - 1 Because we need minus 1 for the current column
      // isCheckedAll[tableIndex] is true
      isCheckedAll[tableIndex] = false
      setIsCheckedAll({ ...isCheckedAll })
    }

    // update selectedColumns
    tables[tableIndex].selectedColumns = selectedColumns

    form.setValue('tables', tables, { shouldDirty: true })
  }

  const handleUpdateInfoTable = useCallback(
    (tableIndex: number, info: ANY) => {
      const tables = form.getValues('tables')
      tables[tableIndex] = {
        ...tables[tableIndex],
        ...info,
      }
      form.setValue('tables', tables, { shouldDirty: true })
    },
    [form],
  )

  const handleUpdateDescription = useCallback(
    (tableIndex: number, col: string, description: string) => {
      // TODO: @Duc - Added by Leo, please check
      const tables = form.getValues('tables')
      if (tables[tableIndex].descriptions === undefined) {
        tables[tableIndex].descriptions = tables[tableIndex].columns.reduce(
          (acc: Record<string, string>, col: string) => {
            acc[col] = ''
            return acc
          },
          {},
        )
      }

      tables[tableIndex].descriptions[col] = description
    },
    [form],
  )

  useEffect(() => {
    if (
      form &&
      incrementalRefreshTables &&
      Object.keys(incrementalRefreshTables).length > 0
    ) {
      const tables = form.getValues('tables')

      Object.keys(incrementalRefreshTables).forEach((tableName) => {
        const table = tables.find(
          (t: { name: string }) => normalizeName(t.name) === tableName,
        )
        if (table) {
          table.incrementalRefreshColumns = incrementalRefreshTables[tableName]
        }
      })

      form.setValue('tables', tables, { shouldDirty: true })
    }
  }, [incrementalRefreshTables, form])

  return (
    <TableFormFactoryCtx.Provider
      value={{
        isCheckedAll,
        handleUpdateInfoTable,
        handleUpdateDescription,
        handleUpdateAllColumnsChecked,
        handleUpdateColumnsChecked,
        incrementalRefresh,
        handleUpdateIncrementalRefresh,
      }}
    >
      {children}
    </TableFormFactoryCtx.Provider>
  )
}

export const useTableFormFactoryContext = () => {
  const ctx = React.useContext(TableFormFactoryCtx)
  if (!ctx)
    throw new Error(
      'TableFormFactoryCtx must be used within a TableFormFactoryProvider',
    )

  return ctx
}

export default TableFormFactoryCtx
