import React from 'react'
import { BsGraphUp } from 'react-icons/bs'
import { FiFileText } from 'react-icons/fi'
import { GrAnalytics } from 'react-icons/gr'
import { IoPlayOutline } from 'react-icons/io5'
import { LuDatabase, LuDollarSign, LuUsers } from 'react-icons/lu'
import { MdKeyboardArrowDown, MdOutlineSettings } from 'react-icons/md'
import { Link } from 'react-router-dom'

import IcClose from '@/components/Icon/close.svg?react'
import IcToggleMenu from '@/components/Icon/toggleMenu.svg?react'
import { BREAKPOINTS } from '@/constants/common'
import { useProject } from '@/contexts/projectContext'
import { useSideBar } from '@/contexts/sidebarContext'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { useWindowSize } from '@/hooks/useWindowSize'
import { cn } from '@/lib/utils'
import ImagePreview from '@/pages/projectEdit/projectThumbnail/imagePreview/imagePreview'

import Logo from '../Logo/Logo'
import Rodal from '../rodal/rodal'
import { confirmRodal } from '../rodal/rodal.helper'
import { Button } from '../ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/UserNav/dropdown-menu'
import { SideBarBody } from './components'

const ProjectItems = [
  {
    to: '/dataset',
    icon: <FiFileText className="w-[20px] h-[20px]" />,
    label: 'inputData',
    active: false,
  },
  {
    to: '/data-warehouse',
    icon: <LuDatabase className="w-[20px] h-[20px]" />,
    label: 'reports',
    active: false,
  },
]

const ProjectInfoItems = [
  {
    to: '/settings',
    icon: <MdOutlineSettings className="w-[15px] h-[15px]" />,
    label: 'settings',
    active: false,
  },
  {
    to: '/tuning',
    icon: <BsGraphUp className="w-[15px] h-[15px]" />,
    label: 'tuning',
    active: false,
  },
  {
    to: '/users',
    icon: <LuUsers className="w-[15px] h-[15px]" />,
    label: 'users',
    active: false,
  },
  {
    to: '/billing',
    icon: <LuDollarSign className="w-[15px] h-[15px]" />,
    label: 'billingSection',
    active: true,
  },
  {
    to: '/go-live',
    icon: <IoPlayOutline className="w-[15px] h-[15px]" />,
    label: 'goLive',
    active: false,
  },
  {
    to: '/usage',
    icon: <GrAnalytics className="w-[15px] h-[15px]" />,
    label: 'usage',
    active: false,
  },
]

const SideBar = () => {
  const { t } = useTrans()
  const { width } = useWindowSize()
  const { location, params, navigate } = useRouter()
  const { isOpenMenu, handleToggleMenu } = useSideBar()
  const { isNewQuestion } = useProject()

  const pid = params.pid
  const isLGScreens = width >= BREAKPOINTS.LG

  const classContainer = cn(
    'w-full h-full md:max-w-[260px] z-20',
    'fixed top-0 left-[-100%] bg-black/[0.8]',
    'overflow-hidden transition-all',
    {
      'cursor-pointer': !isLGScreens,
      'left-0 transition-all': isOpenMenu,
      '!left-0 relative': isLGScreens,
    },
  )

  const { projects, project, playground } = useGetProject({ pid: pid! })

  return (
    <React.Fragment>
      {!isLGScreens && (
        <div
          className="
            w-full container bg-primary-1 flex justify-between 
            sticky top-0 h-[80px] items-center z-20 shrink-0
          "
        >
          <Logo />
          <Button
            className="!bg-white !rounded-[8px]"
            onClick={handleToggleMenu}
          >
            <IcToggleMenu />
          </Button>
        </div>
      )}
      <div className={classContainer} onClick={handleToggleMenu}>
        <div className="w-11/12 md:w-full h-full relative bg-primary-1 flex flex-col justify-between">
          <div>
            <div className="w-full flex justify-between md:justify-start pt-[30px] md:pt-[40px] px-6">
              <Logo />
              <Button
                className="!bg-white !rounded-[8px] block md:hidden"
                onClick={handleToggleMenu}
                size={'sm'}
              >
                <IcClose />
              </Button>
            </div>
            {/* Name Project here */}
            <div className="w-full px-6 py-2">
              <div className="sm:w-[300px] md:w-[212px] lg:w-[212px] relative mt-6 flex justify-between">
                <DropdownMenu>
                  <DropdownMenuTrigger
                    asChild
                    className="focus-visible:border-none focus-visible:ring-0"
                  >
                    <Button
                      borderRadius="full"
                      className="bg-[#19255C] hover:bg-[#2D43E0] w-full flex justify-between rounded-lg h-11 px-4"
                      variant="sidebar"
                    >
                      <div className="w-[88%] flex items-center gap-2">
                        <ImagePreview
                          imageClassName="h-[24px] w-[24px] !rounded-full"
                          path={project.coverFile}
                          teramotLogo
                        />
                        <p
                          className={`h4 text-white text-[13px] text-left truncate cursor-default pr-2`}
                        >
                          {String(project?.name || playground?.name || '')}
                        </p>
                      </div>
                      <MdKeyboardArrowDown
                        className="translate-x-2"
                        size="20px"
                      />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    className="w-[80vw] sm:w-[300px] md:w-[212px]
                    lg:w-[212px] max-h-[300px] overflow-y-auto"
                  >
                    {projects &&
                      Object.values(projects).map((project) => (
                        <DropdownMenuItem
                          className="w-full py-1.5 px-5 cursor-pointer flex items-center gap-2"
                          disabled={project.isCopy}
                          key={project.pid}
                          onClick={() => {
                            navigate('/project/' + project.pid + '/dataset')
                          }}
                        >
                          <ImagePreview
                            imageClassName="h-[24px] w-[24px] !rounded-full"
                            path={project.coverFile}
                            pid={project.pid}
                            teramotLogo
                          />
                          <p className="w-full truncate">{project.name}</p>
                        </DropdownMenuItem>
                      ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
            <SideBarBody>
              {ProjectItems.map((item, index) => {
                const to = item.to
                return (
                  <Link
                    key={index}
                    onClick={(e) => {
                      e.preventDefault()

                      const typePage = location.pathname.split('/').pop()
                      if (typePage === 'question') {
                        if (isNewQuestion) {
                          confirmRodal({
                            children: ({ onClose }) => {
                              return (
                                <Rodal
                                  message={t('unsavedChanges')}
                                  onCancel={onClose}
                                  onClose={onClose}
                                  onConfirm={() => {
                                    navigate('/project/' + pid + to)
                                  }}
                                  title={t('warning')}
                                />
                              )
                            },
                          })
                          return
                        }
                      }
                      return navigate('/project/' + pid + to)
                    }}
                    to={'/project/' + pid + to}
                  >
                    <SideBarBody.Item
                      active={location.pathname.includes(to)}
                      icon={item.icon}
                      label={t(item.label)}
                      labelClassName="text-white"
                    ></SideBarBody.Item>
                  </Link>
                )
              })}
            </SideBarBody>
          </div>
          <div className="pb-4">
            <p className="w-full px-8 pt-8 h5 text-[14px] text-[#BDC5E9] border-t-2 border-[#1e2a63]">
              {t('projectInfo')}
            </p>

            <SideBarBody>
              {ProjectInfoItems.map((item, index) => {
                const to = item.to
                return (
                  <Link
                    key={index}
                    onClick={(e) => {
                      e.preventDefault()

                      const typePage = location.pathname.split('/').pop()
                      if (typePage === 'question') {
                        if (isNewQuestion) {
                          confirmRodal({
                            children: ({ onClose }) => {
                              return (
                                <Rodal
                                  message={t('unsavedChanges')}
                                  onCancel={onClose}
                                  onClose={onClose}
                                  onConfirm={() => {
                                    navigate('/project/' + pid + to)
                                  }}
                                  title={t('warning')}
                                />
                              )
                            },
                          })
                          return
                        }
                      }
                      return navigate('/project/' + pid + to)
                    }}
                    to={'/project/' + pid + to}
                  >
                    <SideBarBody.Item
                      active={location.pathname.includes(to)}
                      icon={item.icon}
                      isInfoItem
                      label={t(item.label)}
                    ></SideBarBody.Item>
                  </Link>
                )
              })}
            </SideBarBody>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SideBar
