import PageHeader from '@/components/pageHeader/pageHeader'
import Spin from '@/components/spin/spin'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

import BasicInfo from '../components/basicInfo/basicInfo'
import KnowledgeGeneralList from '../components/knowledgeGeneralList/knowledgeGeneralList'
import useTuning from '../tuning.hook'

const TuningGeneral = () => {
  const { params } = useRouter()
  const { t } = useTrans()
  const { pid, dataset_id } = params
  const { project, configuration, botName, isProjectValid } = useTuning()

  const knowledgeGeneral =
    configuration?.knowledgeGeneral?.map((item) => {
      return {
        name: t('generalKnowledge'),
        knowledge: item,
      }
    }) ?? []

  return (
    <PageHeader haveSubSidebar title={t('subTitleTuningGeneral')}>
      {pid && isProjectValid ? (
        <div className="space-y-4">
          <BasicInfo botName={botName || ''} pid={pid} />
          <KnowledgeGeneralList
            configuration={{ ...configuration }}
            datasetId={dataset_id!}
            list={knowledgeGeneral}
            pid={pid}
            project={project}
          />
        </div>
      ) : (
        <div className="w-full h-[200px] flex justify-center items-center text-white">
          <Spin />
        </div>
      )}
    </PageHeader>
  )
}

export default TuningGeneral
