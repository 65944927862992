import clsx from 'clsx'

import FolderIcon from '@/components/Icon/folder.svg?react'

import useImagePreview from './imagePreview.hook'

interface IProps {
  path?: File | string
  imageClassName?: string
  teramotLogo?: boolean
  pid?: string
}

const ImagePreview = (props: IProps) => {
  const { src } = useImagePreview({ path: props.path, pid: props.pid })

  const containerClassName = clsx(
    'shrink-0 w-[240px] h-[126px] rounded-lg overflow-hidden flex justify-center items-center border',
    props.imageClassName,
  )

  return (
    <div className={containerClassName}>
      {src ? (
        <img
          alt="Cover"
          className="w-full h-full object-cover block"
          src={src}
        />
      ) : props.teramotLogo ? (
        <img
          alt="Cover"
          className="w-full h-full object-cover block"
          src={'/TeramotLogo.png'}
        />
      ) : (
        <FolderIcon className="h-8.5 w-auto" />
      )}
    </div>
  )
}

export default ImagePreview
