import { useCallback, useEffect } from 'react'

import { CHATBOT_SOCKET_EMIT, CHATBOT_SOCKET_LISTEN } from '@/constants/common'
import { useRouter } from '@/hooks/useRouter'
import { chatBotSocket } from '@/services/socket/chatbot.socket'
import { ANY } from '@/types'

import { useChatBotContext } from '../chatbot.context'
import { MESSAGE_TYPE } from '../messageItem/messageItem.props'
import { UseChatMessageProps } from './useChatMessage.props'

export const useChatMessage = (props: UseChatMessageProps) => {
  const { params } = useRouter()
  const { setNewMessage, isShowMessage } = useChatBotContext()
  const clientId = localStorage.getItem('clientId')
  const { pid } = params
  const { updateMessage } = props

  const onMessage = useCallback(
    (data: Record<string, unknown>) => {
      if ((data.meta as { pid: string }).pid === pid) {
        updateMessage({
          text: (data.meta as { message: string }).message,
          sender: MESSAGE_TYPE.BOT,
        })
        if (!isShowMessage) setNewMessage(1)
      }
    },
    [isShowMessage, setNewMessage, updateMessage, pid],
  )

  const handleSendMessage = useCallback(
    (message: string) => {
      chatBotSocket.sendMessage(
        CHATBOT_SOCKET_EMIT.CHAT_MESSAGE,
        { clientId, pid, message: message },
        (response: ANY) => {
          if (response && response.success) {
            updateMessage({ text: message, sender: MESSAGE_TYPE.USER })
          }
        },
      )
    },
    [clientId, pid, updateMessage],
  )

  useEffect(() => {
    chatBotSocket.onListenMessage(
      CHATBOT_SOCKET_LISTEN.CHAT_MESSAGE,
      pid!,
      onMessage,
    )

    return () => {
      chatBotSocket.offListenMessage(CHATBOT_SOCKET_LISTEN.CHAT_MESSAGE)
    }
  }, [onMessage, pid])

  return { handleSendMessage }
}
