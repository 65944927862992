import { useCallback, useEffect, useState } from 'react'

import { setIncrementalRefreshTables } from '@/features/project/projectSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useDebounce } from '@/hooks/useDebounce'
import { ANY } from '@/types'

import { useTableFormFactoryContext } from '../../tableFormFactory'
import { UseRowUpdateDescriptionFormProps } from './rowUpdateDescriptionForm.props'

export const useRowUpdateDescriptionForm = (
  props: UseRowUpdateDescriptionFormProps,
) => {
  const [description, setDescription] = useState(props.description)
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined,
  )

  const onChange = useDebounce((e: ANY) => {
    setDescription(e.target.value)
  }, 300)

  const incrementalRefreshTables = useAppSelector(
    (state) => state.project.incrementalRefreshTables,
  )

  const dispatch = useAppDispatch()
  const { incrementalRefresh } = useTableFormFactoryContext()

  const findSelectedValue = useCallback(() => {
    return (
      Object.keys(incrementalRefreshTables?.[props.tableName!] || {}).find(
        (key) =>
          props.tableName &&
          incrementalRefreshTables[props.tableName]?.[key] === props.columnName,
      ) || undefined
    )
  }, [incrementalRefreshTables, props.tableName, props.columnName])

  const handleRadioChange = (field: string) => {
    setSelectedValue(field)
  }

  const handleSubmit = (e: ANY) => {
    e.preventDefault()
    e.stopPropagation()

    if (props.tableName && selectedValue) {
      dispatch(
        setIncrementalRefreshTables({
          tableName: props.tableName,
          field: selectedValue,
          value: props.columnName,
        }),
      )
    }

    props.handleUpdateDescription(description)
    props.onClose()
  }

  const handleOnClose = () => {
    setSelectedValue(findSelectedValue())
    props.onClose()
  }

  useEffect(() => {
    if (props.checked === false && props.tableName && props.columnName) {
      const existingValue = findSelectedValue()

      if (existingValue) {
        setSelectedValue(undefined)
        dispatch(
          setIncrementalRefreshTables({
            tableName: props.tableName,
            field: existingValue,
            value: null,
          }),
        )
      }
    }

    if (props.isOpen) {
      setSelectedValue(findSelectedValue())
    }
  }, [
    dispatch,
    findSelectedValue,
    props.checked,
    props.columnName,
    props.isOpen,
    props.tableName,
  ])

  return {
    onChange,
    handleSubmit,
    incrementalRefresh,
    handleRadioChange,
    selectedValue: selectedValue ?? findSelectedValue(),
    handleOnClose,
  }
}
