import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'
import { FiPlus } from 'react-icons/fi'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'

import useAddMember from './addMember.hook'

const AddMember = () => {
  const { t } = useTrans()
  const { isAdding, setAdding, onChangeInput, onAddMember, onCancelAddMember } =
    useAddMember()

  if (isAdding) {
    return (
      <div className="flex gap-2">
        <Input onChange={onChangeInput} placeholder={t('email')} type="email" />
        <Button borderRadius="large" onClick={onAddMember} type="button">
          <CheckIcon className="h-4 w-4" />
        </Button>
        <Button
          borderRadius="large"
          className="border border-n-2"
          onClick={onCancelAddMember}
          type="button"
          variant="link"
        >
          <Cross2Icon className="h-4 w-4" />
        </Button>
      </div>
    )
  }

  return (
    <Button
      className="flex gap-2 items-center"
      onClick={() => setAdding(true)}
      variant="secondary"
    >
      {t('add')}
      <FiPlus size="18px" />
    </Button>
  )
}

export default AddMember
