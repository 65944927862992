import clsx from 'clsx'

import { SidebarLabelItemProps } from './sidebarLabelItem.props'

const SidebarLabelItem = (props: SidebarLabelItemProps) => {
  const { item, isActive } = props

  return (
    <div className="flex flex-1 items-center justify-between gap-2 size-full overflow-hidden">
      <p
        className={clsx(
          'text-ellipsis overflow-hidden line-clamp-1 text-wrap break-all w-full text-left',
          {
            'font-bold': isActive,
          },
        )}
      >
        {item.label}
      </p>
    </div>
  )
}

export default SidebarLabelItem
