import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  clearPlaygrounds,
  deepCopy,
  setCurrentProject,
  setSelectedPlayground,
  uploadProject,
} from '@/features/project/projectSlice'
import { isAuth } from '@/helpers/auth'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

import { useProjectEditContext } from './projectEdit.context'

const useProjectEdit = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector((state) => state?.project?.currentProject)
  const selectedPlayground = useAppSelector(
    (state) => state.project?.selectedPlayground ?? {},
  )
  const [isSelectedPlayground] = useState<boolean>(
    selectedPlayground.name !== undefined &&
      selectedPlayground.description !== undefined,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { t } = useTrans()
  const { i18n } = useTranslation()
  const { location, params, navigate } = useRouter()
  const { form, setFilenameThumbnail, filenameThumbnail } =
    useProjectEditContext()

  const pid = params.pid
  const author = isAuth().email
  const currentLanguage = i18n.language.split('-')[0]

  const isCreateNewProject = useMemo(() => {
    return location.pathname === '/project/new'
  }, [location.pathname])

  useEffect(() => {
    if (!pid) return

    dispatch(setCurrentProject(pid))
  }, [dispatch, pid])

  useEffect(() => {
    if (form && author) {
      form.setValue('email', [author])
      form.setValue('author', author)
      form.setValue('language', currentLanguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [author])

  useEffect(() => {
    if (isSelectedPlayground) {
      form.setValue('name', selectedPlayground.name)
      form.setValue('description', selectedPlayground.description)
      form.setValue('email', selectedPlayground.users)
      form.setValue('author', selectedPlayground.author)
      form.setValue('etlID', selectedPlayground.etlID)
      form.setValue('language', selectedPlayground.lang || currentLanguage)
      setFilenameThumbnail((selectedPlayground.coverFile as string) || '')
    } else if (project && pid) {
      setFilenameThumbnail(project.coverFile || '')
      form.setValue('name', project.name || '')
      form.setValue('description', project.description || '')
      form.setValue('email', project.users)
      form.setValue('author', project.author)
      form.setValue('language', project?.lang || currentLanguage)
    }
  }, [
    currentLanguage,
    form,
    isSelectedPlayground,
    pid,
    project,
    selectedPlayground,
    setFilenameThumbnail,
  ])

  useEffect(() => {
    if (isSelectedPlayground) {
      const subscription = form.watch((value: ANY) => {
        dispatch(
          setSelectedPlayground({
            ...selectedPlayground,
            name: value.name,
            description: value.description,
            users: value.email,
          }),
        )
      })
      return () => subscription.unsubscribe()
    }
  }, [form, selectedPlayground, dispatch, isSelectedPlayground])

  const prepareFormData = (
    payload: ANY,
    author: string,
    pid: string | undefined,
    filenameThumbnail: string,
  ) => {
    const { thumbnail, name, description, email, language } = payload

    const data = new FormData()
    data.append('name', name)
    data.append('description', description || '')
    data.append('author', author)
    data.append('company', isAuth().company)
    data.append('users', email)
    data.append('language', language)

    if (pid) data.append('pid', pid)

    if (thumbnail) {
      data.append('file', thumbnail)
      data.append('coverFile', thumbnail.name)
    } else if (!filenameThumbnail || !pid) {
      data.append('coverFile', '')
    }

    return data
  }

  const preparePlaygroundFormData = (payload: ANY, author: string) => {
    const { thumbnail, name, description, email, etlID, language } = payload

    const data = new FormData()
    data.append('name', name)
    data.append('description', description || '')
    data.append('author', author)
    data.append('company', isAuth().company)
    data.append('users', JSON.stringify(email))
    data.append('etlID', etlID)
    data.append('language', language)

    if (thumbnail) {
      data.append('file', thumbnail)
      data.append('coverFile', thumbnail.name)
    }

    return data
  }

  const saveSelectedPlayground = async (selectedPlayground: {
    data: FormData
  }) => {
    try {
      return await dispatch(deepCopy(selectedPlayground))
    } catch (error) {
      console.error(error)
    }
  }

  const handleProjectUpload = async (dataCreate: { data: FormData }) => {
    const promise = dispatch(uploadProject(dataCreate)).then((res: ANY) => {
      navigate(`/project/${res.pid}/dataset`)
    })

    toast.promise(promise, {
      pending: t('processingUpdateProject'),
      success: t('successUpdateProject'),
      error: t('errorUpdateProject'),
    })

    return promise
  }

  const onSubmit = form.handleSubmit(async (payload: ANY) => {
    setIsLoading(true)
    const data = prepareFormData(payload, author, pid, filenameThumbnail)

    try {
      if (isSelectedPlayground) {
        const playgroundData = preparePlaygroundFormData(payload, author)

        const dataCreate = { data: playgroundData }

        const savePlaygroundPromise = saveSelectedPlayground(dataCreate).then(
          (savedPlayground) => {
            dispatch(clearPlaygrounds())
            dispatch(setSelectedPlayground({}))
            navigate('/projects')
            return savedPlayground
          },
        )

        toast.promise(savePlaygroundPromise, {
          pending: t('processingSavePlayground'),
          success: t('successSavePlayground'),
          error: t('errorSavePlayground'),
        })

        await savePlaygroundPromise
      } else {
        const dataCreate = { data }
        await handleProjectUpload(dataCreate)
      }
    } catch (error) {
      console.error(error)
      navigate('/projects')
      throw error
    } finally {
      setIsLoading(false)
    }
  })

  return {
    isCreateNewProject,
    isSelectedPlayground,
    form,
    onSubmit,
    isLoading,
  }
}

export default useProjectEdit
