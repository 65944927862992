import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from '@/components/ui/accordion'
import { ANY } from '@/types'

import TableFormItem from '../tableFormItem/tableFormItem'
import AccordionTriggerHeader from './accordionHeader/accordionHeader'
import { TableFormAccordingProps } from './tableFormAccording.props'

const TableFormAccording = (props: TableFormAccordingProps) => {
  const { list, isCreate } = props

  return (
    <Accordion className="w-full" type="multiple">
      {list.map((item: ANY, tableIndex: number) => {
        const tableName =
          item.displayName ?? item.name?.split('/').pop()?.split('.')?.shift()
        return (
          <AccordionItem
            className="mb-6"
            key={tableIndex}
            value={tableIndex.toString()}
          >
            <AccordionTriggerHeader
              description={item?.description}
              isCreate={isCreate}
              item={item}
              name={tableName}
              tableIndex={tableIndex}
            />
            <AccordionContent>
              <TableFormItem
                isCreate={isCreate}
                item={item}
                tableIndex={tableIndex}
                tableName={tableName}
              />
            </AccordionContent>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default TableFormAccording
