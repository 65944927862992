import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

import { IconTooltipProps } from './iconTooltip.props'

const IconTooltip = (props: IconTooltipProps) => {
  const { icon, message, disabled, className, iconClassName, type } = props
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger
          className={iconClassName}
          disabled={disabled}
          type={type}
        >
          {icon}
        </TooltipTrigger>
        <TooltipContent className={className}>{message}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default IconTooltip
