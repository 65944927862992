import { useEffect, useRef } from 'react'

import { genTableQuestionUUID } from '@/helpers/genUUID'
import { useGetCurrentProject } from '@/hooks/useGetCurrentProject/useGetCurrentProject'
import { useRouter } from '@/hooks/useRouter'
import {
  FormInstanceProps,
  IProcessedData,
  IProcessedDataColumn,
  ITable,
  IWarehouse,
} from '@/types'

export const useDataWarehouseUpdate = () => {
  const { params } = useRouter()
  const { id } = params
  const { project } = useGetCurrentProject()

  const warehouse = project?.warehouse?.find((wh) => wh.id === id)
  const goldTable = {
    name: warehouse?.name || '',
    columns: warehouse?.etl?.columns || [],
    description: warehouse?.etl.description || '',
    lastModelDescription: warehouse?.etl.lastModelDescription || '',
  }
  return { warehouse, goldTable }
}

const createTableFromDataItem = (dataItem: IProcessedData) => ({
  columns: dataItem.columns?.map((col) => col.name) || [],
  columnDataTypes: Object.fromEntries(
    dataItem.columns?.map((col) => [col.name, '']) || [],
  ),
  descriptions: Object.fromEntries(
    dataItem.columns?.map((col) => [col.name, col.description || '']) || [],
  ),
  selectedColumns: Object.fromEntries(
    dataItem.columns?.map((col) => [col.name, false]) || [],
  ),
  name: dataItem.name,
  table_id: dataItem.id,
})

const updateExistingTable = (
  existingTable: ITable,
  newColumns: IProcessedDataColumn[],
  removedColumns: string[],
) => {
  const updatedTable = {
    ...existingTable,
    columns:
      existingTable.columns?.filter((col) => !removedColumns.includes(col)) ||
      [],
    columnDataTypes: Object.fromEntries(
      Object.entries(existingTable.columnDataTypes || {}).filter(
        ([colName]) => !removedColumns.includes(colName),
      ),
    ),
    descriptions: Object.fromEntries(
      Object.entries(existingTable.descriptions || {}).filter(
        ([colName]) => !removedColumns.includes(colName),
      ),
    ),
    selectedColumns: Object.fromEntries(
      Object.entries(existingTable.selectedColumns || {}).filter(
        ([colName]) => !removedColumns.includes(colName),
      ),
    ),
  }

  if (newColumns.length > 0) {
    updatedTable.columns = [
      ...(updatedTable.columns || []),
      ...newColumns.map((col) => col.name),
    ]
    updatedTable.columnDataTypes = {
      ...updatedTable.columnDataTypes,
      ...Object.fromEntries(newColumns.map((col) => [col.name, ''])),
    }
    updatedTable.descriptions = {
      ...updatedTable.descriptions,
      ...Object.fromEntries(
        newColumns.map((col) => [col.name, col.description || '']),
      ),
    }
    updatedTable.selectedColumns = {
      ...updatedTable.selectedColumns,
      ...Object.fromEntries(newColumns.map((col) => [col.name, false])),
    }
  }

  return updatedTable
}

const processSources = (
  warehouseSources: IWarehouse['sources'],
  processedData: IProcessedData[],
) => {
  const updatedSources = warehouseSources.filter((source) =>
    processedData.some((dataItem) => dataItem.id === source.input_id),
  )

  processedData.forEach((dataItem) => {
    const existingSourceIndex = updatedSources.findIndex(
      (source) => source.input_id === dataItem.id,
    )

    if (existingSourceIndex === -1) {
      updatedSources.push({
        input_id: dataItem.id,
        name: dataItem.name,
        description: dataItem.id,
        tables: [createTableFromDataItem(dataItem)],
      })
    } else {
      const existingSource = updatedSources[existingSourceIndex]
      const existingTable = existingSource.tables?.[0] || {}

      const newColumns = dataItem.columns?.filter(
        (col) => !existingTable.columns?.includes(col.name),
      )
      const removedColumns = existingTable.columns?.filter(
        (col) => !dataItem.columns?.some((newCol) => newCol.name === col),
      )

      updatedSources[existingSourceIndex] = {
        ...existingSource,
        tables: [
          updateExistingTable(existingTable, newColumns, removedColumns),
          ...existingSource.tables.slice(1),
        ],
      }
    }
  })

  return updatedSources
}

const processQuestionsOrKnowledge = (
  items: IWarehouse['knowledge'] | IWarehouse['questions'],
) =>
  items?.map((item) => {
    const _id = item._id ?? genTableQuestionUUID()
    const content = typeof item.content === 'string' ? item.content : item
    return { content, cacheId: null, _id }
  })

export const useDataWarehouseChange = (
  form: FormInstanceProps,
  warehouse: IWarehouse,
  goldTable?: Pick<
    IProcessedData,
    'name' | 'columns' | 'description' | 'lastModelDescription'
  >,
) => {
  const refOldWarehouse = useRef<string>()
  const { processedData } = useGetCurrentProject()

  useEffect(() => {
    try {
      if (!warehouse) return
      const warehouseString = JSON.stringify(warehouse)
      if (refOldWarehouse.current === warehouseString) return

      refOldWarehouse.current = warehouseString

      const filteredProcessedData = processedData.filter(
        (dataItem) => dataItem.id !== warehouse.etl.id,
      )

      const updatedSources = processSources(
        warehouse.sources,
        filteredProcessedData,
      )
      const newQuestions = processQuestionsOrKnowledge(warehouse.questions)
      const newKnowledge = processQuestionsOrKnowledge(warehouse.knowledge)

      const updatedWarehouse = {
        ...warehouse,
        sources: updatedSources,
        questions: newQuestions,
        knowledge: newKnowledge,
        add_question: '',
        add_knowledge: '',
        goldTable,
      }

      form.reset(updatedWarehouse)
    } catch (error) {
      throw new Error('Error processing warehouse data')
    }
  }, [form, goldTable, processedData, warehouse])
}
