import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input, InputPassword } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'
import { FormInstanceProps } from '@/types'

const Salesforce = ({ form }: { form: FormInstanceProps }) => {
  const { t } = useTrans()
  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="user"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">{t('user')}</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="pwd"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">{t('password')}</FormLabel>
            <FormControl>
              <InputPassword
                {...field}
                autoComplete="off"
                placeholder={t('placeholderPassword')}
                type="password"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="security_token"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">
              {t('securityToken')}
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}

export default Salesforce
