import { useEffect } from 'react'
import { toast } from 'react-toastify'

import { userRole } from '@/constants/common'
import {
  deleteProject,
  getWarehouseStatus,
} from '@/features/project/projectSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import useGetUserRole from '@/hooks/useGetUserRole/useGetUserRole'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

import { confirmRodal } from '../rodal'
import Rodal from '../rodal/rodal'
import { IUserMainCard } from './mainCard.props'

const useMainCard = (props: IUserMainCard) => {
  const { pid } = props
  const { t } = useTrans()
  const dispatch = useAppDispatch()
  const { navigate } = useRouter()
  const { role } = useGetUserRole({ pid })

  const projects = useAppSelector((state) => state.project.projects)
  const project = projects[pid!] || {}

  const goToSettingPage = () => {
    if (!pid) return

    navigate(`/project/${pid}/edit`)
  }

  const goToDetailsPage = async () => {
    if (!pid) return
    if (!project.playgroundID || project.isCopy === false) {
      if (role === userRole.guest) {
        navigate(`/conversation/${pid}`)
      } else {
        navigate(`/project/${pid}/dataset`)
      }
      return
    }

    if (project.isCopy) {
      toast.info(t('loadingProject'))
    }
  }

  useEffect(() => {
    if (!pid || !project.isCopy) return

    const interval = setInterval(() => {
      dispatch(getWarehouseStatus(pid!))
    }, 5000)

    return () => clearInterval(interval)
  }, [pid, project.isCopy, dispatch])

  const handleDeleteProject = () => {
    if (!pid) return

    confirmRodal({
      children: ({ onClose }) => {
        return (
          <Rodal
            message={t('descriptionDeleteKnowledge')}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={() => {
              const promise = dispatch(deleteProject({ pid }))
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              toast.promise(promise as any, {
                pending: t('deleteProjectToastPending'),
                success: t('deleteProjectToastSuccess'),
                error: t('deleteProjectToastError'),
              })
            }}
            textCancel={t('deleteProjectPopupCancel')}
            textConfirm={t('deleteProjectPopupConfirm')}
            title={t('titleDeleteKnowledge')}
          />
        )
      },
    })
  }

  return { goToSettingPage, goToDetailsPage, handleDeleteProject }
}

export default useMainCard
