import PageHeader from '@/components/pageHeader/pageHeader'
import { useTrans } from '@/hooks/useTranslation'

import Interaction from './interaction/interaction'
import { useUsage } from './usage.hook'

const Usage = () => {
  const { t } = useTrans()
  const { channelInfo, usersChannel, isAdminOrTeramot } = useUsage()

  return (
    <PageHeader title={t('usage')}>
      <div className="w-full sm:px-8">
        <div className="w-full flex flex-col p-8 rounded-lg gap-6">
          <div className="w-full">
            <p className="caption2">{t('amountOfQuestionsPerChannel')}</p>

            <div className="pl-5 w-full flex flex-col gap-1 mt-1 overflow-scroll  max-h-80">
              <div className={`w-full grid grid-cols-[100px_1fr] items-center`}>
                <p className="sm">Teramot :</p>
                <p className="sm font-medium">
                  <Interaction
                    isShownCost={isAdminOrTeramot}
                    value={channelInfo?.['teramot']}
                  />
                </p>
              </div>
              <div className={`w-full grid grid-cols-[100px_1fr] items-center`}>
                <p className="sm">Whatsapp :</p>
                <p className="sm font-medium">
                  <Interaction
                    isShownCost={isAdminOrTeramot}
                    value={channelInfo?.['whatsapp']}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="w-full">
            <p className="caption2">
              {t('amountOfQuestionsPerUserPerChannel')}
            </p>

            <div className="pl-5 w-full flex flex-col gap-1 mt-1 overflow-scroll max-h-[600px] relative bg-white">
              <div
                className={`w-full flex gap-10 items-center sticky top-0 bg-white z-10`}
              >
                <p className="sm w-[400px] flex-shrink-0"></p>
                <p className="sm font-medium w-[120px] text-center flex-shrink-0">
                  Teramot
                </p>
                <p className="sm font-medium w-[120px] text-center flex-shrink-0">
                  Whatsapp
                </p>
              </div>
              {usersChannel.map((userChannel, index) => (
                <div className="w-full flex gap-10 items-center" key={index}>
                  <p className="sm w-[400px] flex-shrink-0 pl-2">
                    {userChannel.user.split('-')[0]}
                  </p>
                  <p className="sm font-medium w-[120px] text-center flex-shrink-0">
                    <Interaction
                      isShownCost={isAdminOrTeramot}
                      value={userChannel.channel_info?.['teramot']}
                    />
                  </p>
                  <p className="sm font-medium w-[120px] text-center flex-shrink-0">
                    <Interaction
                      isShownCost={isAdminOrTeramot}
                      value={userChannel.channel_info?.['whatsapp']}
                    />
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  )
}

export default Usage
