import JsonEditor from '@/components/jsonInput/jsonInput'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'
import { FormInstanceProps } from '@/types'

const BigQuery = ({ form }: { form: FormInstanceProps }) => {
  const { t } = useTrans()
  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="gcp_project_id"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">
              {t('GCP project ID')}
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="gcp_dataset_id"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">
              {t('GCP dataset ID')}
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="gcp_credentials"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">
              {t('GCP credentials')}
            </FormLabel>
            <FormControl>
              <JsonEditor onChange={field.onChange} value={field.value} />
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  )
}

export default BigQuery
