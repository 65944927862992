import { FormField } from '@/components/ui/form'
import { useTrans } from '@/hooks/useTranslation'

import { TableFormFactoryProvider } from '../../tableFormFactory'
import TableFormFactory from '../../tableFormFactory/tableFormFactory'
import { useDatasetCreateContext } from '../datasetCreate.context'
import DatasetCreateLayout from '../datasetCreateLayout/datasetCreateLayout'
import { useUpdateColumnLeavePage } from './updateColumn.hook'
import { UpdateColumnProps } from './updateColumn.props'
import { useUpdateColumnSchema } from './updateColumn.validate'

const UpdateColumn = (props: UpdateColumnProps) => {
  const { form } = props
  const { dataset } = useDatasetCreateContext()

  const { type } = dataset

  useUpdateColumnLeavePage({
    form,
    meta: dataset,
  })

  return (
    <div className="w-full overflow-y-auto">
      <TableFormFactoryProvider form={form}>
        <FormField
          control={form.control}
          name="tables"
          render={({ field }) => {
            if (!field.value || !field.value.length) return <div></div>
            const _ = field.value

            return (
              <TableFormFactory isCreate={type !== 'SPREADSHEET'} list={_} />
            )
          }}
        />
      </TableFormFactoryProvider>
    </div>
  )
}

const UpdateColumnContainer = () => {
  const { schema } = useUpdateColumnSchema()
  const { t } = useTrans()

  return (
    <DatasetCreateLayout
      description={t('updateColumnsDescription')}
      schema={schema}
      title={t('updateColumnsTitle')}
    >
      {(props) => <UpdateColumn {...props} />}
    </DatasetCreateLayout>
  )
}

export default UpdateColumnContainer
