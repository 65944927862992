import RefreshCheckbox from '@/components/refreshCheckbox/refreshCheckbox'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { useTrans } from '@/hooks/useTranslation'

import { useDataWarehouseCreateContext } from '../dataWarehouseCreate.context'
import DataWarehouseCreateLayout from '../dataWarehouseCreateLayout/dataWarehouseCreateLayout'
import { useCreateNameLeavePage } from './updateName.hook'
import { UpdateNameProps } from './updateName.props'
import { useUpdateNameSchema } from './updateName.validate'

const UpdateName = (props: UpdateNameProps) => {
  const { form } = props
  const { t } = useTrans()
  const { warehouseTable } = useDataWarehouseCreateContext()
  useCreateNameLeavePage({
    form,
    meta: warehouseTable,
  })

  return (
    <div className="flex flex-col justify-center gap-8">
      <FormField
        control={form.control}
        name="refresh"
        render={({ field }) => {
          return (
            <FormItem className="w-full">
              <FormControl>
                <RefreshCheckbox
                  isChecked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )
        }}
      />
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => {
          return (
            <FormItem className="w-full">
              <FormLabel className="caption2" requiredField>
                {t('tableName')}
              </FormLabel>
              <FormControl>
                <Textarea
                  onChange={field.onChange}
                  placeholder={t('typeANameForTheTable')}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )
        }}
      />
    </div>
  )
}

const UpdateNameContainer = () => {
  const { t } = useTrans()
  const { schema } = useUpdateNameSchema()
  return (
    <DataWarehouseCreateLayout
      schema={schema}
      title={t('titleDataWarehouseName')}
    >
      {(props) => <UpdateName {...props} />}
    </DataWarehouseCreateLayout>
  )
}

export default UpdateNameContainer
