import { CheckedState } from '@radix-ui/react-checkbox'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { HiOutlinePencilAlt } from 'react-icons/hi'

import IconTooltip from '@/components/iconTooltip'
import { Checkbox } from '@/components/ui/checkbox'
import { onFocusInputById } from '@/helpers/common'
import { useToggle } from '@/hooks/useToggle'
import { useTrans } from '@/hooks/useTranslation'

import RowUpdateDescriptionForm from './rowUpdateDescriptionForm/rowUpdateDescriptionForm'

interface IProps {
  checked: boolean
  label: ReactNode | string
  isRowSelectALL?: boolean

  // eslint-disable-next-line no-unused-vars
  onChange: (checked: CheckedState) => void
  description?: string
  handleUpdateDescription?: (description: string) => void
  classNameContainer?: string
  tableName?: string
  isCreate?: boolean
}
const RowCheckbox = (props: IProps) => {
  const { isRowSelectALL, tableName } = props

  const { t } = useTrans()
  const classNameContainer = clsx(
    'py-4 px-6 border rounded-lg border-border-1 space-y-4 w-full',
    {
      '!border-0': isRowSelectALL,
      '!gap-2': isRowSelectALL,
    },
    props.classNameContainer,
  )
  const classNameLabel = clsx(
    'w-full flex items-center gap gap-6 cursor-pointer',
    {
      '!border-0 !gap-4 flex-row-reverse': isRowSelectALL,
    },
  )

  const { toggle, handleToggle } = useToggle()

  return (
    <div className={classNameContainer}>
      <label className={classNameLabel} onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={props.checked}
          onChange={(e) => e.stopPropagation()}
          onCheckedChange={props.onChange}
          onClick={(e) => e.stopPropagation()}
        />
        <div className="w-full flex justify-between items-center gap-1">
          <div className="flex flex-col gap-2">
            <p>{props.label}</p>
            {props?.description && (
              <p>
                <span>{t('description')}: </span>
                {props.description}
              </p>
            )}
          </div>

          {!isRowSelectALL && (
            <div
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                handleToggle()
                onFocusInputById(('focus-' + props.label) as string)
              }}
            >
              <IconTooltip
                icon={<HiOutlinePencilAlt className="mt-2" size="20px" />}
                message={t('iconUpdateDescription')}
              />
            </div>
          )}
        </div>
      </label>
      {!isRowSelectALL && (
        <RowUpdateDescriptionForm
          IdFocus={('focus-' + props.label) as string}
          columnName={props.label as string}
          description={props.description!}
          handleUpdateDescription={props.handleUpdateDescription!}
          isCreate={props.isCreate}
          isOpen={toggle}
          onClose={handleToggle}
          tableName={tableName}
          {...props}
        />
      )}
    </div>
  )
}

export default RowCheckbox
