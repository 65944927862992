import { ScrollArea } from '@/components/ui/scroll-area'
import { useTrans } from '@/hooks/useTranslation'

import RowCheckbox from '../../rowCheckbox/rowCheckbox'
import { useTableFormFactoryContext } from '../tableFormFactory.context'
import { SHOW_MAX_ITEMS } from './tableFormItem.constant'
import { TableFormItemProps } from './tableFormItem.props'
const TableFormItem = (props: TableFormItemProps) => {
  const { item, tableIndex, tableName, isCreate } = props

  const {
    handleUpdateColumnsChecked,
    handleUpdateDescription,
    isCheckedAll,
    handleUpdateAllColumnsChecked,
  } = useTableFormFactoryContext()
  const { t } = useTrans()

  const size = item.columns?.length ?? 0
  const totalColumns = item?.columns?.length || 0
  const selectedCount = Object.entries(item.selectedColumns).filter(
    ([, value]) => value,
  ).length

  return (
    <div>
      <div className="pt-4 px-3 border-t-[1px]">
        <ScrollArea
          className="w-full px-3"
          style={{
            // 55 is the height of the row
            // 16 is the margin between rows
            // minHeight: '400px',
            height: !(size <= SHOW_MAX_ITEMS)
              ? `${Math.min(size, SHOW_MAX_ITEMS) * 62 + (Math.min(size, SHOW_MAX_ITEMS) - 1) * 16}px`
              : 'auto',
          }}
        >
          <div className="w-full flex flex-col gap-4">
            <div className="w-full flex items-center justify-between gap-6 pr-1 h-[28px]">
              <p className="text-[12px] font-[500] px-2 justify-end rounded-[4px] bg-[#F1F6FE] h-full leading-[28px]">
                {t('columnsSelected', { selectedCount, totalColumns })}
              </p>
              <RowCheckbox
                checked={isCheckedAll[tableIndex]}
                classNameContainer="!p-0 !w-fit"
                isRowSelectALL
                label={t('checkAllColumns')}
                onChange={(checked) => {
                  handleUpdateAllColumnsChecked(tableIndex, Boolean(checked))
                }}
              />
            </div>
            {item.columns?.map((col: string, index: number) => {
              const checked = item?.selectedColumns?.[col] ?? false
              const dataType = item.columnDataTypes[col]

              const description = item?.descriptions?.[col] ?? ''
              const labelName = dataType ? `${col} (${dataType})` : col

              return (
                <RowCheckbox
                  checked={checked}
                  description={description}
                  handleUpdateDescription={(description) => {
                    handleUpdateDescription(tableIndex, col, description)
                  }}
                  isCreate={isCreate}
                  key={item.name + index}
                  label={labelName}
                  onChange={() => {
                    handleUpdateColumnsChecked(tableIndex, col, !checked)
                  }}
                  tableName={tableName}
                />
              )
            })}
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

export default TableFormItem
