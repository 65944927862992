import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input, InputPassword } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'
import { FormInstanceProps } from '@/types'

const Snowflake = ({ form }: { form: FormInstanceProps }) => {
  const { t } = useTrans()
  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="account"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">{t('account')}</FormLabel>
            <FormControl>
              <Input {...field} placeholder={t('accountPlaceholder')} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="dbName"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">{t('dbName')}</FormLabel>
            <FormControl>
              <Input {...field} placeholder={t('placeholderDBName')} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="schema"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">{t('schema')}</FormLabel>
            <FormControl>
              <Input {...field} placeholder={t('schemaPlaceholder')} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="warehouse"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">
              {t('warehouse')}
            </FormLabel>
            <FormControl>
              <Input {...field} placeholder={t('warehousePlaceholder')} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="user"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">{t('user')}</FormLabel>
            <FormControl>
              <Input
                {...field}
                autoComplete="off"
                placeholder={t('placeholderUser')}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="pwd"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-text-default">{t('password')}</FormLabel>
            <FormControl>
              <InputPassword
                {...field}
                autoComplete="off"
                placeholder={t('placeholderPassword')}
                type="password"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}

export default Snowflake
