import dayjs from 'dayjs'

import { useTrans } from '@/hooks/useTranslation'

import { useExtraDataETL } from './extraDataETL.hook'
import { ExtraDataETLProps } from './extraDataETL.props'

const ExtraDataETL = (props: ExtraDataETLProps) => {
  const { status, updatedAt, editedAt } = props
  const { t } = useTrans()
  const { getStatusDetails } = useExtraDataETL()

  const { text: statusText, bgColor, textColor } = getStatusDetails(status)

  return (
    <div className="space-y-2">
      <p className="text-[12px] text-text-gray leading-4">
        {t('updatedAt')}: {dayjs(updatedAt).format('DD/MM/YY HH:mm')}
      </p>
      <p className="text-[12px] text-text-gray leading-4">
        {t('lastEdition')}:{' '}
        {editedAt ? dayjs(editedAt).format('DD/MM/YY HH:mm') : '-'}
      </p>
      <p
        className="w-fit px-2 text-[12px] rounded-sm"
        style={{
          backgroundColor: bgColor,
          color: textColor,
          lineHeight: '24px',
          fontWeight: 500,
        }}
      >
        {statusText}
      </p>
    </div>
  )
}

export default ExtraDataETL
