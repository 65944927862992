import { Button } from '@/components/ui/button'
import {
  createKnowledgeGeneral,
  removeKnowledgeGeneral,
  updateKnowledgeGeneral,
} from '@/features/project/projectSlice'
import { useTrans } from '@/hooks/useTranslation'

import { useKnowledgeList } from './knowledgeGeneralList.hook'
import { KnowledgeListProps } from './knowledgeGeneralList.props'
import KnowledgeFormItem from './knowledgeItem/knowledgeFormItem/knowledgeFormItem'
import KnowledgeItem from './knowledgeItem/knowledgeItem'

const KnowledgeGeneralList = (props: KnowledgeListProps) => {
  const { list, configuration, pid, project } = props
  const { t } = useTrans()

  const { handleSaveKnowledge, dispatch, isCreate, toggleCreate, isDisabled } =
    useKnowledgeList({
      pid,
      configuration,
      project,
    })

  return (
    <div className="w-full">
      <div className="w-full flex flex-col gap-6">
        {list.map((item, index) => {
          return (
            <KnowledgeItem
              content={item.knowledge}
              datasetId={props.datasetId}
              dispatchCallback={updateKnowledgeGeneral}
              index={index}
              key={index}
              onDelete={() => dispatch(removeKnowledgeGeneral({ pid, index }))}
              pid={props.pid}
              title={item.name}
            />
          )
        })}
      </div>
      {isCreate && (
        <KnowledgeFormItem
          defaultValues={{
            pid: props.pid,
            datasetId: props.datasetId,
            knowledge: '',
            index: list.length,
          }}
          dispatchCallback={createKnowledgeGeneral}
          toggleEdit={toggleCreate}
        />
      )}
      <div className="w-full flex justify-end gap-6 mt-8">
        {!isCreate && (
          <Button hidden={true} onClick={toggleCreate} variant="terciary">
            {t('addNewKnowledge')}
          </Button>
        )}

        <Button
          disabled={isDisabled}
          onClick={handleSaveKnowledge}
          variant="primary"
        >
          {t('save')}
        </Button>
      </div>
    </div>
  )
}

export default KnowledgeGeneralList
