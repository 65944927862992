import { HiOutlinePencilAlt } from 'react-icons/hi'

import IconTooltip from '@/components/iconTooltip'
import { onFocusInputById } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'

import { useBasicInfo } from './basicInfo.hook'
import { BasicInfoProps } from './basicInfo.props'
import BasicInfoForm from './basicInfoForm/basicInfoForm'

const BasicInfo = (props: BasicInfoProps) => {
  const { t } = useTrans()
  const { isEdit, toggleEdit } = useBasicInfo(props)
  return (
    <div className="w-full px-8 py-4 relative shadow-md rounded-md border">
      {!isEdit && (
        <div className="flex items-center justify-between">
          <p>
            <span className="caption2">{t('botName')}: </span>
            <span className="text-sm">{props.botName}</span>
          </p>
          <div className="flex justify-end">
            <IconTooltip
              icon={
                <HiOutlinePencilAlt
                  className="cursor-pointer"
                  fontWeight={700}
                  onClick={() => {
                    toggleEdit()
                    onFocusInputById('focus-botName')
                  }}
                />
              }
              message={t('iconUpdateBasicInfo')}
            />
          </div>
        </div>
      )}
      {isEdit && (
        <BasicInfoForm
          IdFocus="focus-botName"
          defaultValues={{
            pid: props.pid,
            botName: props.botName,
          }}
          toggleEdit={toggleEdit}
        />
      )}
    </div>
  )
}

export default BasicInfo
