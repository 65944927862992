import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { useWarningBeforeLeave } from '@/hooks/useValidateCreateDatasetBeforeLeave'
import { ANY } from '@/types'

import { useDatasetCreateContext } from '../datasetCreate.context'
import { AllowUpdateSpreadsheet } from './updateSpreadsheet.constant'
import {
  IUseABSUpdateSpreadsheetProps,
  UseUpdateSpreadsheetLeavePageProps,
} from './updateSpreadsheet.props'

export const useUpdateSpreadsheetFile = (
  props: IUseABSUpdateSpreadsheetProps,
) => {
  const { params } = useRouter()
  const { t } = useTrans()
  const pid = params.pid
  const { form } = props
  const { handleUpdateCurrentDataset } = useDatasetCreateContext()
  const [file, setFile] = useState<File | undefined>(undefined)
  const [files, setFiles] = useState<FileList | null>(null)
  const [selectedButton, setSelectedButton] = useState<'upload' | 'link'>(
    'upload',
  )

  const onChangeUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _file = e.target.files?.[0]
    const fileType = _file?.type

    if (
      !fileType ||
      !AllowUpdateSpreadsheet.some((type) => fileType.includes(type))
    ) {
      toast.error(t('inValidAllowUploadFileSpreadsheet'))
      return
    }

    if (_file) {
      // set value check is upload file
      form.setValue('isUploadFile', _file?.name ? _file?.name : 'false')
      form.setValue('fileToUpload', _file)
      setFile(_file)
      return
    }
  }

  const onChangeUploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _files = e.target.files
    const listFiles = Array.from(_files as FileList)
    for (const file of listFiles) {
      const fileType = file?.type
      if (
        !fileType ||
        !AllowUpdateSpreadsheet.some((type) => fileType.includes(type))
      ) {
        toast.error(t('inValidAllowUploadFileSpreadsheet'))
        return
      }
    }

    if (_files) {
      // set value check is upload file
      form.setValue('isUploadFile', true)
      form.setValue('fileToUpload', _files)

      setFiles(_files)
      return
    }
  }

  useEffect(() => {
    if (!files) return
    form.setValue('fileToUpload', files)
  }, [files, form])

  const overrideEventDefaults = (event: ANY) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleFiles = (fileList: ANY) => {
    if (fileList) {
      const files = Array.from(fileList)
      const _file = files[0] as File
      const fileType = _file?.type

      if (
        !fileType ||
        !AllowUpdateSpreadsheet.some((type) => fileType.includes(type))
      ) {
        toast.error(t('inValidAllowUploadFileSpreadsheet'))
        return
      }

      setFile(_file)
      setFiles(fileList)
      form.setValue('isUploadFile', true)
    }
  }

  const handleDragAndDropFiles = (event: ANY) => {
    overrideEventDefaults(event)
    if (!event.dataTransfer) return
    handleFiles(event.dataTransfer.files)
  }

  const handleButtonSelectionChange = (selection: 'upload' | 'link') => {
    setFile(undefined)
    setFiles(null)
    form.setValue('fileLink', '')
    form.setValue('isUploadFile', false)
    form.setValue('fileToUpload', null)

    setSelectedButton(selection)
  }

  const handleDeleteFile = () => {
    setFile(undefined)
    setFiles(null)
    form.setValue('isUploadFile', false)
    form.setValue('fileToUpload', null)
  }

  return {
    form,
    pid,
    file,
    files,
    handleDragAndDropFiles,
    overrideEventDefaults,
    onChangeUploadFile,
    handleUpdateCurrentDataset,
    onChangeUploadFiles,
    handleDeleteFile,
    selectedButton,
    setSelectedButton: handleButtonSelectionChange,
  }
}

export const useUpdateSpreadsheetLeavePage = (
  props: UseUpdateSpreadsheetLeavePageProps,
) => {
  const { setStep, setCurrentDataset } = useDatasetCreateContext()

  const { form, meta } = props

  const validate = () => {
    const isUploadFile = form.getValues('isUploadFile')
    return isUploadFile || meta?.name || meta?.description || meta?.type
  }

  const { blocker } = useWarningBeforeLeave({
    validate,
    setStep,
    setCurrentDataset,
  })

  return { blocker }
}
