import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import Phone from '@/components/ui/phone/phone'
import { onEnterPress } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

import useFormAddPhoneNumber from './formAddPhoneNumber.hook'
import { FormAddPhoneNumberProps } from './formAddPhoneNumber.props'

const FormAddPhoneNumber = (props: FormAddPhoneNumberProps) => {
  const { disabled, submitCallback } = props
  const { t } = useTrans()
  const { form, onSubmit } = useFormAddPhoneNumber({
    submitCallback: submitCallback,
  })

  return (
    <Form {...form}>
      <form
        className="w-full"
        onKeyDown={onEnterPress(() => {
          form.handleSubmit(onSubmit as ANY)()
        })}
        onSubmit={onSubmit}
      >
        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormLabel className="text-base" requiredField>
                    {t('newUser')}
                  </FormLabel>
                  <FormControl>
                    <Phone
                      containerClass="h-11 w-full"
                      disabled={disabled}
                      inputClass="!h-full peoplePhone !w-full"
                      masks={{ ar: '(.) .. ....-....' }}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  </FormControl>
                </FormItem>
              )
            }}
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormLabel className="text-base" requiredField>
                    {t('nameUser')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="min-h-9 mt-4"
                      disabled={disabled}
                      onChange={field.onChange}
                      placeholder={t('typeYourName')}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
        </div>
        <div className="flex justify-end mt-3">
          <Button
            className="min-w-[80px]"
            disabled={disabled || !form.formState.isDirty}
            onClick={onSubmit}
            type="button"
            variant="primary"
          >
            {t('addPhone')}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default FormAddPhoneNumber
