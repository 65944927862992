import PageHeader from '@/components/pageHeader/pageHeader'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormControl, FormField } from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { useTrans } from '@/hooks/useTranslation'

import FormAddEmail from './formAddEmail/formAddEmail'
import RowEmailCheckbox from './rowPhoneCheckbox/rowCheckbox'
import useTeramotChannel from './teramotChannel.hook'

const TeramotChannelForm = () => {
  const { t } = useTrans()

  const {
    isActivateTeramot,
    form,
    onSubmit,
    handleAddEmail,
    emails,
    handleRemoveEmail,
    handleCheckEmail,
  } = useTeramotChannel()

  return (
    <>
      <Form {...form}>
        <form className="w-full mt-10" onSubmit={onSubmit}>
          <div className="flex space-x-2">
            <FormField
              control={form.control}
              name="activateTeramot"
              render={({ field }) => {
                return (
                  <>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        id="activateTeramot"
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <Label
                      // eslint-disable-next-line max-len
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      htmlFor="activateTeramot"
                    >
                      {t('activate')}
                    </Label>
                  </>
                )
              }}
            />
          </div>

          {emails && emails?.length > 0 && (
            <div className="w-full flex flex-col space-y-4 mt-9">
              {emails?.map((item) => (
                <RowEmailCheckbox
                  checked={item.checked as boolean}
                  disableCheckbox={false}
                  key={item.email}
                  label={
                    <p className="text-sm font-medium">
                      {item.email}
                      <span className="ml-6 text-text-gray">{item.name}</span>
                    </p>
                  }
                  onChange={(checked) =>
                    handleCheckEmail(item.email, Boolean(checked))
                  }
                  onRemove={() => handleRemoveEmail(item.email)}
                />
              ))}
            </div>
          )}
        </form>
      </Form>
      <div className="mt-9">
        <FormAddEmail
          disabled={!isActivateTeramot}
          submitCallback={handleAddEmail}
        />
      </div>
      <div className="mt-[84px] flex justify-end pb-4">
        <Button
          className="min-w-[80px]"
          onClick={onSubmit}
          type="submit"
          variant={'primary'}
        >
          {t('save')}
        </Button>
      </div>
    </>
  )
}

const TeramotChannel = () => {
  const { t } = useTrans()
  return (
    <PageHeader haveSubSidebar title={t('title_updateTypeInUserChange')}>
      <div className="w-fill">
        <TeramotChannelForm />
      </div>
    </PageHeader>
  )
}

export default TeramotChannel
