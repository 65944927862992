import { IoArrowForwardOutline } from 'react-icons/io5'

import PageHeader from '@/components/pageHeader/pageHeader'
import Spin from '@/components/spin/spin'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useTrans } from '@/hooks/useTranslation'

import { CREATE_DATASET_STEP } from '../datasetCreate.constant'
import useFormDatasetCreate from './datasetCreateLayout.hook'
import { DatasetCreateLayoutProps } from './datasetCreateLayout.props'

const DatasetCreateLayout = (props: DatasetCreateLayoutProps) => {
  const { title, children, schema, defaultValues, description } = props
  const { t } = useTrans()

  const { handlePreStep, currentStep, onSubmit, form, isSubmitting } =
    useFormDatasetCreate({
      schema,
      defaultValues,
    })

  return (
    <Form {...form}>
      <PageHeader
        description={description}
        haveSubSidebar
        resource="dataset"
        title={title}
      >
        <form className="w-full relative" onSubmit={onSubmit}>
          {children({ form })}
          <div className="flex justify-end gap-4 sticky bottom-0 left-0  mt-5">
            {/* Type is first step should dont show Pre */}
            {currentStep != CREATE_DATASET_STEP.NAME && (
              <Button
                className="min-w-[80px]"
                onClick={() => handlePreStep()}
                type="button"
                variant={'secondary'}
              >
                {t('previous')}
              </Button>
            )}

            <Button
              className="min-w-[80px] flex items-center justify-center gap-2"
              disabled={(props.isDisabledNext || isSubmitting) ?? false}
              type="submit"
              variant="primary"
            >
              {currentStep !== CREATE_DATASET_STEP.COLUMN
                ? t('next')
                : t('createDataSource')}
              {isSubmitting ? (
                <Spin className="!size-[14px] !text-[#7C7C7C] !fill-gray-200" />
              ) : (
                <IoArrowForwardOutline />
              )}
            </Button>
          </div>
        </form>
      </PageHeader>
    </Form>
  )
}

export default DatasetCreateLayout
