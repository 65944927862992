import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Textarea } from '@/components/ui/textarea'
import { useTrans } from '@/hooks/useTranslation'
import { cn } from '@/lib/utils'

import { useDatasetCreateContext } from '../datasetCreate.context'
import DatasetCreateLayout from '../datasetCreateLayout/datasetCreateLayout'
import { useCreateNameLeavePage, useGetDataSource } from './updateName.hook'
import { UpdateNameProps } from './updateName.props'
import { useUpdateNameSchema } from './updateName.validate'

const UpdateName = (props: UpdateNameProps) => {
  const { form } = props
  const { t } = useTrans()
  const { dataSources } = useGetDataSource()
  const { dataset } = useDatasetCreateContext()
  useCreateNameLeavePage({
    form,
    meta: dataset,
  })

  return (
    <div className="flex flex-col justify-center gap-8 mb-10">
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => {
          return (
            <FormItem className="w-[50%] min-w-[300px]">
              <FormLabel className="text-text-default caption2 text-base">
                {t('datasetName')}
              </FormLabel>
              <FormControl>
                <Input
                  className="placeholder:text-sm"
                  onChange={field.onChange}
                  placeholder={t('typeANameForTheDataset')}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )
        }}
      />
      <FormField
        control={form.control}
        name="description"
        render={({ field }) => {
          return (
            <FormItem className="w-[50%] min-w-[300px]">
              <FormLabel className="text-text-default caption2 text-base">
                {t('datasetDescription')}
              </FormLabel>
              <p className="block text-[#757575] text-sm !m-0">
                {t('datasetDescriptionMessage')}
              </p>
              <FormControl>
                <Textarea
                  className="placeholder:text-sm h-[100px] !mt-1"
                  onChange={field.onChange}
                  placeholder={t('typeADescriptionForTheDataset')}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )
        }}
      />
      <FormField
        control={form.control}
        name="type"
        render={({ field }) => {
          return (
            <FormItem className="w-full">
              <FormLabel className="text-text-default caption2 text-base">
                {t('dataSourceSelection')}
              </FormLabel>
              <FormControl>
                <RadioGroup
                  className="w-full flex gap-8 flex-col md:flex-row"
                  defaultValue={field.value}
                  onValueChange={(value) => {
                    field.onChange(value)
                  }}
                >
                  {dataSources.map(({ type, title }, index) => (
                    <div className="flex items-center" key={index}>
                      <RadioGroupItem hidden id={type} value={type} />
                      <Label
                        className={cn(
                          'inline-flex items-center justify-center',
                          'whitespace-nowrap base1 font-semibold',
                          'transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring',
                          'disabled:pointer-events-none disabled:opacity-50 border border-input',
                          'bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
                          'px-4 py-[16px] rounded-lg min-w-36 w-full',
                          'cursor-pointer',
                          {
                            '!bg-accent-1': field.value === type,
                            '!text-white': field.value === type,
                          },
                        )}
                        htmlFor={type}
                      >
                        {title}
                      </Label>
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )
        }}
      />
    </div>
  )
}

const UpdateNameContainer = () => {
  const { t } = useTrans()
  const { schema } = useUpdateNameSchema()
  return (
    <DatasetCreateLayout
      schema={schema}
      subtitle={t('newDataSourceDescription')}
      title={t('newDataSource')}
    >
      {(props) => <UpdateName {...props} />}
    </DatasetCreateLayout>
  )
}

export default UpdateNameContainer
