import { useEffect, useMemo, useRef } from 'react'

import { getWarehouseStatus } from '@/features/project/projectSlice'
import { isArrayNotEmpty } from '@/helpers/isArrayNotEmpty'
import { isObjectNotEmpty } from '@/helpers/isObjectNotEmpty'
import { useAppDispatch } from '@/hooks'
import { useGetCurrentProject } from '@/hooks/useGetCurrentProject/useGetCurrentProject'
import { useRouter } from '@/hooks/useRouter'

import {
  DATA_WAREHOUSE_POLLING_INTERVAL,
  POOLING_EACH_STATUS,
} from './projectLayout.constant'

export const useProjectPollingDataWarehouse = () => {
  const { warehouse, dataset, isCopy } = useGetCurrentProject()
  const { params } = useRouter()
  const { pid } = params
  const dispatch = useAppDispatch()

  const refInterval = useRef<NodeJS.Timeout>()

  const isHasPendingWarehouse = useMemo(() => {
    let isHasPendingWarehouse = false
    let isHasPendingDataset = false
    if (isArrayNotEmpty(warehouse))
      isHasPendingWarehouse = warehouse.some((wh) =>
        POOLING_EACH_STATUS.includes(wh?.etl?.status),
      )

    if (isObjectNotEmpty(dataset))
      isHasPendingDataset = Object.values(dataset).some((dataset) =>
        POOLING_EACH_STATUS.includes(dataset?.etl?.status),
      )

    if (isCopy && (!isArrayNotEmpty(warehouse) || !isObjectNotEmpty(dataset))) {
      isHasPendingWarehouse = true
    }

    return isHasPendingWarehouse || isHasPendingDataset
  }, [dataset, isCopy, warehouse])

  useEffect(() => {
    if (isHasPendingWarehouse) {
      refInterval.current = setInterval(() => {
        dispatch(getWarehouseStatus(pid!))
      }, DATA_WAREHOUSE_POLLING_INTERVAL)
    } else clearInterval(refInterval.current)

    return () => {
      clearInterval(refInterval.current)
    }
  }, [dispatch, isHasPendingWarehouse, pid])
}
