import { useMemo } from 'react'

import { BASE_URL } from '@/constants/common'
import { isAuth } from '@/helpers/auth'
import { useRouter } from '@/hooks/useRouter'

interface IProps {
  path?: string | File
  pid?: string
}
const useImagePreview = (props: IProps) => {
  const { params } = useRouter()
  const author = isAuth().email

  const src = useMemo(() => {
    if (!props.path) return null

    // The image uploaded
    if (typeof props.path === 'string') {
      return (
        BASE_URL +
        '/backend/' +
        author +
        '/' +
        (props.pid ?? params.pid) +
        '/' +
        props.path
      )
    }

    // The new image just selected
    return URL.createObjectURL(props.path)
  }, [author, params.pid, props.path, props.pid])

  return { src }
}

export default useImagePreview
