import { useState } from 'react'

import { useToggle } from '@/hooks/useToggle'

const useAccordionHeader = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false)

  const { toggle: isEdit, handleToggle: handleToggleEdit } = useToggle()

  return { isEdit, handleToggleEdit, isAccordionOpen, setIsAccordionOpen }
}

export default useAccordionHeader
