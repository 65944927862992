import { useTrans } from '@/hooks/useTranslation'

import { Button } from '../ui/button'
import { IRodalProps } from './rodal.props'

const Rodal = (props: IRodalProps) => {
  const {
    title,
    textConfirm,
    textCancel,
    message,
    onConfirm,
    onCancel,
    onClose,
  } = props
  const { t } = useTrans()

  return (
    <div className="custom-ui border p-6 px-10 bg-white shadow-2xl rounded-2xl">
      <h1 className="h3 text-orange-500">{title}</h1>
      <p>{message}</p>
      <div className="flex gap-2 mt-3">
        {onCancel && (
          <Button
            className="w-fit"
            onClick={() => {
              onClose()
              onCancel && onCancel()
            }}
            variant="secondary"
          >
            {textCancel ?? t('no')}
          </Button>
        )}

        <Button
          className="w-fit"
          onClick={() => {
            onConfirm()
            onClose()
          }}
          variant="primary"
        >
          {textConfirm ?? t('yes')}
        </Button>
      </div>
    </div>
  )
}

export default Rodal
