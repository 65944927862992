import { Accordion } from '@/components/ui/accordion'
import { FormField } from '@/components/ui/form'
import { useGetCurrentProject } from '@/hooks/useGetCurrentProject/useGetCurrentProject'
import { useTrans } from '@/hooks/useTranslation'

import DataWarehouseCreateLayout from '../dataWarehouseCreateLayout/dataWarehouseCreateLayout'
import { TableFormFactory, TableFormFactoryProvider } from './components'
import { SelectInputProps } from './selectInput.props'
import { useSelectInputSchema } from './selectInput.validate'

const SelectInput = (props: SelectInputProps) => {
  const { form } = props

  return (
    <div className="w-full overflow-y-auto">
      <TableFormFactoryProvider form={form}>
        <FormField
          control={form.control}
          name="datasets"
          render={({ field }) => {
            if (!field.value || !field.value.length) return <div></div>
            const list = field.value

            return (
              <Accordion
                className="w-full"
                collapsible
                defaultValue={'0'}
                type="single"
              >
                <TableFormFactory list={list} />
              </Accordion>
            )
          }}
        />
      </TableFormFactoryProvider>
    </div>
  )
}

const SelectInputContainer = () => {
  const { t } = useTrans()
  const { schema } = useSelectInputSchema()
  const { processedData } = useGetCurrentProject()

  return (
    <DataWarehouseCreateLayout
      defaultValues={{
        datasets: processedData,
      }}
      schema={schema}
      title={t('titleDataWarehouseName')}
    >
      {(props) => <SelectInput {...props} />}
    </DataWarehouseCreateLayout>
  )
}

export default SelectInputContainer
