import { FaRegTrashAlt } from 'react-icons/fa'
import { FiPlus } from 'react-icons/fi'

import IcUpload from '@/components/Icon/upload.svg?react'
import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'
import { cn } from '@/lib/utils'

import { useDatasetCreateContext } from '../datasetCreate.context'
import DatasetCreateLayout from '../datasetCreateLayout/datasetCreateLayout'
import {
  useUpdateSpreadsheetFile,
  useUpdateSpreadsheetLeavePage,
} from './updateSpreadsheet.hook'
import { UpdateSpreadsheetProps } from './updateSpreadsheet.props'
import { useUpdateSpreadsheetSchema } from './updateSpreadsheet.validate'

const UpdateSpreadsheet = (props: UpdateSpreadsheetProps) => {
  const { t } = useTrans()
  const {
    form,
    files,
    onChangeUploadFiles,
    handleDragAndDropFiles,
    overrideEventDefaults,
    selectedButton,
    setSelectedButton,
    handleDeleteFile,
  } = useUpdateSpreadsheetFile({
    form: props.form,
  })

  const { dataset } = useDatasetCreateContext()
  useUpdateSpreadsheetLeavePage({
    form: form,
    meta: dataset,
  })

  const baseButtonStyles = cn(
    'inline-flex items-center justify-center',
    'whitespace-nowrap base1 font-semibold',
    'transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring',
    'disabled:pointer-events-none disabled:opacity-50 border border-input',
    'bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
    'px-4 py-[16px] rounded-lg min-w-36 w-[156px] h-12',
    'cursor-pointer text-black',
  )

  return (
    <div className="flex flex-col justify-start gap-8 mb-20">
      <div>
        <p className="mb-2 text-text-default">
          {t('spreadSheetSourceSelection')}
        </p>
        <div className="flex gap-4">
          <Button
            className={cn(
              baseButtonStyles,
              selectedButton === 'upload' && '!bg-accent-1 !text-white',
            )}
            onClick={() => setSelectedButton('upload')}
            type="button"
          >
            {t('uploadFile')}
          </Button>
          <Button
            className={cn(
              baseButtonStyles,
              selectedButton === 'link' && '!bg-accent-1 !text-white',
            )}
            onClick={() => setSelectedButton('link')}
            type="button"
          >
            {t('addLink')}
          </Button>
        </div>
      </div>
      {selectedButton === 'upload' ? (
        <div className="flex justify-start gap-8">
          <FormField
            control={form.control}
            name="files"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel
                    onDragEnter={overrideEventDefaults}
                    onDragLeave={overrideEventDefaults}
                    onDragOver={overrideEventDefaults}
                    onDrop={overrideEventDefaults}
                  >
                    <div
                      className="w-full py-4 px-8 border rounded-xl"
                      onDragEnter={overrideEventDefaults}
                      onDragLeave={overrideEventDefaults}
                      onDragOver={overrideEventDefaults}
                      onDrop={handleDragAndDropFiles}
                    >
                      <div className="flex gap-2">
                        <IcUpload />
                        <p className="text-base font-semibold text-lg text-n-3">
                          {t('chooseAFileOrDragItHere')}
                        </p>
                      </div>
                      <p className="text-sm mt-2 text-n-3">
                        {t('chooseAFileOrDragItHereDescription')}
                      </p>
                      <p className="text-[12px] mt-2 text-n-3">
                        {t('fileTypes')}
                      </p>
                      <div className="pt-4">
                        {files?.[0]?.name ? (
                          <div
                            className="w-full flex justify-between items-center gap-4
                           bg-[#F5F5F5] p-2 rounded-sm h-9"
                          >
                            <p className="text-n-3">{files?.[0]?.name}</p>
                            <FaRegTrashAlt
                              className="cursor-pointer"
                              onClick={handleDeleteFile}
                            />
                          </div>
                        ) : (
                          <Button
                            className="flex items-center gap-2"
                            onClick={() =>
                              document.getElementById('upload-file')?.click()
                            }
                            type="button"
                            variant="outline"
                          >
                            {t('uploadFile')}
                            <FiPlus size={15} />
                          </Button>
                        )}
                      </div>
                    </div>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      accept=".xlsx, .xls, .csv"
                      className="!hidden"
                      hidden
                      id="upload-file"
                      multiple
                      onChange={onChangeUploadFiles}
                      type="file"
                    />
                  </FormControl>
                </FormItem>
              )
            }}
          />
        </div>
      ) : (
        <div className="flex justify-start gap-8 w-full">
          <FormField
            control={form.control}
            name="fileLink"
            render={({ field }) => {
              return (
                <FormItem className="max-w-[784px] w-full">
                  <FormLabel
                    className="cursor-pointer text-base text-text-default !font-normal"
                    htmlFor="onlineLink"
                  >
                    {t('onlineLink')}
                  </FormLabel>
                  <FormLabel className="block text-[#757575]">
                    {t('fileTypes')}
                  </FormLabel>
                  <div className="flex gap-2 items-center">
                    <FormControl>
                      <Input
                        {...field}
                        id="onlineLink"
                        placeholder={t('placeholderLink')}
                      />
                    </FormControl>
                  </div>
                </FormItem>
              )
            }}
          />
        </div>
      )}
    </div>
  )
}

const UpdateSpreadsheetContainer = () => {
  const { t } = useTrans()
  const { schema } = useUpdateSpreadsheetSchema()

  return (
    <DatasetCreateLayout schema={schema} title={t('uploadYourSpreadsheet')}>
      {(props) => <UpdateSpreadsheet {...props} />}
    </DatasetCreateLayout>
  )
}

export default UpdateSpreadsheetContainer
