import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { FiPlus } from 'react-icons/fi'

import IcClose from '@/components/Icon/close.svg?react'
import IcToggleMenu from '@/components/Icon/toggleMenu.svg?react'
import { Button } from '@/components/ui/button'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

import IconTooltip from '../iconTooltip'
import useSubSidebar from './subSidebar.hook'

interface IProps {
  title: string
  buttons?: Array<{
    key: string
    label: string
    onClick: () => void
    disable?: boolean
    icon?: ReactNode
    checkbox?: ReactNode
  }>
  addButton?: {
    label: string
    onClick: () => void
    disable?: boolean
  }
  renderLabel?: (_item: ANY, _isActive: boolean) => JSX.Element
}
const SubSidebar = (props: IProps) => {
  const { isOpen, handleToggle, isMediumScreens } = useSubSidebar()
  const { location } = useRouter()
  const { t } = useTrans()
  const classContainer = clsx(
    'z-10 flex flex-col absolute md:relative h-full w-full md:w-auto md:bg-white left-[-100%] md:left-0',
    {
      '!left-0 transition-all': isOpen,
    },
  )

  return (
    <React.Fragment>
      {!isMediumScreens && (
        <Button
          className="!bg-white !rounded-[0px] absolute z-10"
          onClick={handleToggle}
        >
          <IcToggleMenu />
        </Button>
      )}

      <div className={classContainer} onClick={handleToggle}>
        <div
          className={clsx(
            'w-11/12 md:w-[260px] grow pt-[50px] pb-14 lg:pb-4 shrink-0',
            'border-r border-border-1 bg-white relative h-full overflow-hidden flex flex-col',
          )}
        >
          <Button
            className="!bg-white !rounded-[0px] absolute top-0 right-0 md:hidden"
            onClick={handleToggle}
          >
            <IcClose />
          </Button>
          <p className="base2 font-semibold mb-8 px-8">{props.title}</p>
          <div className="w-full flex flex-col items-center gap-2 overflow-auto overflow-x-hidden">
            {props.buttons?.map((item, index) => {
              const pathNames = location.pathname.split('/')
              const channel = pathNames.pop()
              return (
                <Button
                  // eslint-disable-next-line max-len
                  className={clsx(
                    'flex w-[90%] justify-start gap-6 rounded-[4px] h-12 mx-auto pl-0 bg-transparent',
                    'hover:bg-button-primary/10 px-8',
                    {
                      'bg-button-primary/10': channel === item.key,
                    },
                  )}
                  disabled={item?.disable ?? false}
                  key={item.label + index}
                  onClick={item.onClick}
                  variant="ghost"
                >
                  {item.icon ? item.icon : ''}

                  {/*  */}
                  {props.renderLabel ? (
                    props.renderLabel(item, channel === item.key)
                  ) : (
                    <span
                      className={clsx('text-ellipsis overflow-hidden', {
                        'font-bold': channel === item.key,
                      })}
                    >
                      {item.label}
                    </span>
                  )}
                  {item.checkbox ? (
                    <span className="ml-auto flex items-center">
                      {item.checkbox}
                    </span>
                  ) : null}
                </Button>
              )
            })}

            {props.addButton &&
              (props.addButton.disable ? (
                <IconTooltip
                  className="!w-full"
                  disabled
                  icon={
                    <Button
                      className={clsx(
                        'flex items-center justify-center gap-2 w-[90%] px-0 mt-2',
                      )}
                      disabled
                      onClick={props.addButton.onClick}
                      variant="secondary"
                    >
                      {props.addButton.label}
                      <FiPlus className="!text-text-gray" height={20} />
                    </Button>
                  }
                  iconClassName="w-full flex items-center justify-center gap-2 w-full px-0 mt-2'"
                  message={
                    props.buttons?.length === 4
                      ? t('goldTablesLimit')
                      : t('necessaryDatasetTooltip')
                  }
                />
              ) : (
                <Button
                  className={clsx(
                    'flex items-center justify-center gap-2 w-[90%] px-0 mt-2',
                  )}
                  onClick={props.addButton.onClick}
                  variant="secondary"
                >
                  {props.addButton.label}
                  <FiPlus height={20} />
                </Button>
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SubSidebar
