import { ScrollArea } from '@/components/ui/scroll-area'
import RowCheckbox from '@/pages/dataWarehouse/components/rowCheckbox/rowCheckbox'
import { IProcessedDataColumn } from '@/types'

import { useTableFormFactoryContext } from '../../tableCreateFormFactory.context'
import { SHOW_MAX_ITEMS } from './tableCreateFormItem.constant'
import { TableFormItemProps } from './tableCreateFormItem.props'

const TableCreateFormItem = (props: TableFormItemProps) => {
  const { columns, processedId, selectedColumns } = props
  const { handleUpdateColumnsChecked } = useTableFormFactoryContext()

  const size = columns?.length ?? 0

  return (
    <div>
      <div className="mt-4 pl-4">
        <ScrollArea
          className="w-full"
          style={{
            // 55 is the height of the row
            // 16 is the margin between rows
            height: !(size <= SHOW_MAX_ITEMS)
              ? `${Math.min(size, SHOW_MAX_ITEMS) * 62 + (Math.min(size, SHOW_MAX_ITEMS) - 1) * 16}px`
              : 'auto',
          }}
        >
          <div className="w-full flex flex-col gap-4 pr-4">
            {columns?.map((col: IProcessedDataColumn) => {
              const checked = selectedColumns.includes(col.id)

              if (checked) col.selected = true

              const description = col?.description ?? ''
              const labelName = col?.name

              return (
                <RowCheckbox
                  checked={col.selected ?? false}
                  description={description}
                  key={col.id}
                  label={labelName}
                  onChange={() => {
                    handleUpdateColumnsChecked(processedId, col.id, !checked)
                  }}
                />
              )
            })}
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

export default TableCreateFormItem
