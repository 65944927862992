import PageHeader from '@/components/pageHeader/pageHeader'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

import KnowledgeList from '../components/knowledgeList/knowledgeList'

const TuningDataset = () => {
  const { params } = useRouter()
  const { t } = useTrans()
  const { pid, dataset_id } = params
  const { project } = useGetProject({ pid: pid! })
  const dataSet = project?.inputData?.[dataset_id!]
  const configuration = project?.configuration

  const knowledges = configuration?.knowledge?.[dataset_id!] ?? []
  const listKnowledge = knowledges.map((item) => {
    return {
      name: t('knowledge'),
      knowledge: item,
    }
  })

  return (
    <PageHeader
      haveSubSidebar
      title={`${t('subTitleTuningSpecific')} ${dataSet?.name ?? ''}`}
    >
      <KnowledgeList datasetId={dataset_id!} list={listKnowledge} />
    </PageHeader>
  )
}

export default TuningDataset
