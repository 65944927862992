/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { confirmRodal } from '@/components/rodal'
import Rodal from '@/components/rodal/rodal'
import { browserCacheKey, ELT_POLLING_STATUS } from '@/constants/common'
import { deleteTable, updateTable } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import useBrowserCache from '@/hooks/useBrowserCache'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { useWarningBeforeLeave } from '@/hooks/useValidateCreateDatasetBeforeLeave'
import { ANY } from '@/types'

import {
  UseDataWarehouseUpdateProps,
  UseValidateLeavePage,
} from './dataWarehouseUpdateLayout.props'

export const useFormDatasetUpdate = ({
  schema,
  defaultValues = {},
  cbSubmit,
}: UseDataWarehouseUpdateProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTrans()
  const { params } = useRouter()
  const { pid, id } = params
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { deleteCacheData } = useBrowserCache(browserCacheKey.tables)

  const props = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const handleCancel = async () => {
    await deleteCacheData({ pid, warehouseId: id })
    props.reset({
      ...defaultValues,
      refresh: defaultValues.refresh ? defaultValues.refresh : false,
      add_question: '',
      add_knowledge: '',
    })
  }

  const onUpdateDatasetToServer = useCallback(
    (payload: Record<string, ANY>) => {
      setIsSubmitting(true)
      if (!pid) {
        throw new Error('Project ID is not defined')
      }

      // remove cacheId before update
      const questions = (payload?.questions ?? []).map((question: ANY) => {
        return {
          _id: question._id,
          content: question.content,
        }
      })

      const knowledge = (payload?.knowledge ?? []).map((knowledge: ANY) => {
        return {
          _id: knowledge._id,
          content: knowledge.content,
        }
      })

      const _payload: Record<string, unknown> = {
        ...payload,
        questions,
        knowledge,
      }

      const promise = dispatch(updateTable(pid, _payload))

      promise.finally(() => {
        setIsSubmitting(false)
      })
      toast.promise(promise, {
        pending: t('processingWarehouse'),
        success: t('updatedWarehouseSuccessfully'),
        error: t('errorUpdateWarehouse'),
      })
      return promise
    },
    [pid],
  )
  const onSubmit = props.handleSubmit(async (payload) => {
    onUpdateDatasetToServer(payload).then(() => {
      deleteCacheData({ pid, warehouseId: id })
      cbSubmit && cbSubmit(payload)
    })
  })

  const isView = !props.formState.isDirty // isDirty: true when user change value of input
  const isDisabled = ELT_POLLING_STATUS.includes(defaultValues?.etl?.status)

  return {
    isView: isView,
    form: props,
    isSubmitting,
    isDisabled,
    onSubmit,
    handleCancel,
  }
}

export const useRemoveDataset = () => {
  const { t } = useTrans()
  const dispatch = useAppDispatch()

  const { params, navigate } = useRouter()
  const { pid, id } = params

  const handleRemoveDataset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    confirmRodal({
      children: ({ onClose }) => {
        return (
          <Rodal
            message={t('descriptionDeleteDataset')}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={() => {
              dispatch(deleteTable(pid!, id!)).then(() => {
                navigate(`/project/${pid}/data-warehouse`)
              })
            }}
            title={t('titleDeleteDataset')}
          />
        )
      },
    })
  }

  return { handleRemoveDataset }
}

export const useValidateLeavePage = (props: UseValidateLeavePage) => {
  const { isView } = props
  const validate = () => {
    return !isView
  }

  const { blocker } = useWarningBeforeLeave({
    validate,
  })

  return { blocker }
}
export default useFormDatasetUpdate
