import { HiOutlinePencilAlt } from 'react-icons/hi'

import IconTooltip from '@/components/iconTooltip'
import { AccordionTrigger } from '@/components/ui/accordion'
import { onFocusInputById } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'
import RowCheckbox from '@/pages/dataset/rowCheckbox/rowCheckbox'

import { useTableFormFactoryContext } from '../../tableFormFactory.context'
import useAccordionHeader from './accordionHeader.hook'
import { AccordionHeaderProps } from './accordionHeader.props'
import UpdateInfoForm from './updateInfoForm/updateInfoForm'

const AccordionTriggerHeader = (props: AccordionHeaderProps) => {
  const { isEdit, handleToggleEdit, isAccordionOpen, setIsAccordionOpen } =
    useAccordionHeader()
  const { tableIndex, item, name, isCreate } = props
  const {
    isCheckedAll,
    handleUpdateAllColumnsChecked,
    handleUpdateIncrementalRefresh,
    incrementalRefresh,
  } = useTableFormFactoryContext()
  const { t } = useTrans()
  const totalColumns = item?.columns?.length || 0
  const selectedCount = Object.entries(item.selectedColumns).filter(
    ([, value]) => value,
  ).length

  return (
    <>
      <AccordionTrigger
        className={'items-start' + (isAccordionOpen ? ' !pb-4' : '')}
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
      >
        <div className="w-full flex flex-col items-start gap-2">
          {isAccordionOpen && (
            <p className="text-[12px] font-light">{t('table')}</p>
          )}
          <div className="w-full flex justify-between items-center">
            <div
              className={`flex flex-col justify-start ${isAccordionOpen ? 'w-[33%]' : 'w-full'}`}
            >
              <h3 className="text-left text-[16px] truncate overflow-hidden">
                {props.name}
              </h3>
              {props.description && (
                <p className="text-left">
                  {t('description')}: {props.description}
                </p>
              )}
            </div>
            {isCreate && isAccordionOpen && (
              <div className={'pl-4 ' + (isAccordionOpen ? 'w-[33%]' : '')}>
                <IconTooltip
                  className="!text-start"
                  icon={
                    <RowCheckbox
                      checked={incrementalRefresh[name] || false}
                      classNameContainer="!p-0 !font-[500]"
                      isRowSelectALL
                      label={t('incrementalRefreshMessageTitle')}
                      onChange={(checked) => {
                        handleUpdateIncrementalRefresh(name, Boolean(checked))
                      }}
                      tableName={name}
                    />
                  }
                  message={t('incrementalRefreshTooltip')}
                  type="button"
                />
              </div>
            )}
            {!isAccordionOpen && (
              <div className="w-full min-w-[300px] flex items-center justify-end gap-6 pr-2">
                <p className="text-[12px] font-[500] px- justify-end rounded-[4px] bg-[#F1F6FE] px-2">
                  {t('columnsSelected', { selectedCount, totalColumns })}
                </p>
                <RowCheckbox
                  checked={isCheckedAll[tableIndex]}
                  classNameContainer="!p-0 !w-fit !font-[500]"
                  isRowSelectALL
                  label={t('checkAll')}
                  onChange={(checked) => {
                    handleUpdateAllColumnsChecked(tableIndex, Boolean(checked))
                  }}
                />
              </div>
            )}
            <div
              className={`${isAccordionOpen ? 'w-[33%]' : ''} flex items-center justify-end gap-6`}
            >
              {!isEdit && isAccordionOpen && (
                <div
                  className="mr-[-22px]"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    handleToggleEdit()
                    onFocusInputById(`focus-name-${props.tableIndex}`)
                  }}
                >
                  <IconTooltip
                    icon={<HiOutlinePencilAlt className="mt-2" size="20px" />}
                    message={t('iconUpdateName')}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </AccordionTrigger>
      {isCreate && incrementalRefresh[name] && isAccordionOpen && (
        <p className="px-6 py-2 font-light text-sm">
          {t('incrementalRefreshMessage')}
        </p>
      )}
      <UpdateInfoForm
        description={props.description}
        displayName={props.name}
        idDescriptionFocus={`focus-description-${props.tableIndex}`}
        idNameFocus={`focus-name-${props.tableIndex}`}
        incrementalRefresh={incrementalRefresh[name]}
        isOpen={isEdit}
        onClose={handleToggleEdit}
        tableIndex={props.tableIndex}
      />
    </>
  )
}

export default AccordionTriggerHeader
