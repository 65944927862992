import { io, Socket } from 'socket.io-client'

import { ANY } from '@/types'

export abstract class AbsSocket {
  protected socket: Socket
  constructor(url: string, path?: string) {
    this.socket = io(url, { path })
  }

  public get isConnected() {
    return this.socket.connected
  }

  public connect(cb?: () => void): void {
    if (this.isConnected) return

    this.socket.connect().on('connect', () => {
      cb && cb()
    })
  }

  public disconnect(): void {
    this.socket.disconnect()
  }

  public sendMessage(
    event: string,
    message: unknown,
    cb?: (_response: ANY) => void,
  ): void {
    this.socket.emit(event, message, cb)
  }

  public onListenMessage<T>(
    event: string,
    pid: string,
    callback: (_data: { pid: string; meta: T }) => void,
  ): void {
    // check is envent is exist
    this.socket.off(event)
    this.socket.on(event, (meta: T) => {
      const data = {
        pid,
        meta,
      }
      callback(data)
    })
  }

  public offListenMessage(event: string) {
    this.socket.off(event)
  }
}
