import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { getProjects } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'

export const useGetBillingParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const pid = searchParams.get('pid')
  const success = searchParams.get('success')
  const canceled = searchParams.get('canceled')
  const tierName = searchParams.get('tier_name')
  const sid = searchParams.get('sid')

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getProjects({}))
  }, [dispatch])

  const clearSearchParams = (keys: string[]) => {
    keys.forEach((key) => searchParams.delete(key))
    setSearchParams(searchParams)
  }
  return { pid, success, canceled, tierName, sid, clearSearchParams }
}
