import { useTrans } from '@/hooks/useTranslation'

export const useExtraDataETL = () => {
  const { t } = useTrans()

  const getStatusDetails = (status: string | undefined) => {
    switch (status) {
      case 'SUCCESSFUL':
        return {
          text: t('connected'),
          bgColor: '#88D45233',
          textColor: '#475C37',
        }
      case 'IN_PROGRESS':
        return {
          text: t('inProgress'),
          bgColor: '#CCCCCC33',
          textColor: '#666666',
        }
      case 'ERROR':
        return {
          text: t('error'),
          bgColor: '#FF000033',
          textColor: '#8B0000',
        }
      case 'OUTDATED':
        return {
          text: t('outdated'),
          bgColor: '#CCCCCC33',
          textColor: '#666666',
        }
      default:
        return {
          text: t('notRun'),
          bgColor: '#CCCCCC33',
          textColor: '#666666',
        }
    }
  }

  return { getStatusDetails }
}
