import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'

import Spin from '@/components/spin/spin'
import { Button } from '@/components/ui/button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

import { usePreviewTable } from './previewTable.hook'

const PreviewTable = () => {
  const { isLoading, errorMessage, hasData, selectedTable } = usePreviewTable()
  const { t } = useTrans()

  const data =
    Array.isArray(selectedTable?.data) && selectedTable.data.length > 0
      ? selectedTable.data
      : null

  const columns: ColumnDef<ANY>[] = data
    ? Object.keys(data[0]).map((key) => ({
        accessorKey: key,
        header: key.charAt(0).toUpperCase() + key.slice(1),
        cell: ({ row }) => (
          <div className="w-full max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">
            {row.getValue(key)}
          </div>
        ),
      }))
    : []

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  if (isLoading) {
    return (
      <div className="w-full h-[200px] flex justify-center items-center text-white">
        <Spin />
      </div>
    )
  }

  if (errorMessage) {
    return (
      <div className="w-full h-[200px] flex justify-center items-center text-red-500">
        {errorMessage}
      </div>
    )
  }

  if (!hasData) {
    return (
      <div className="w-full h-[200px] flex justify-center items-center text-black">
        <span>{t('previewTableWithNoData')}</span>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="relative rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  className="h-24 text-center"
                  colSpan={columns.length}
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="space-x-2">
          <Button
            className="!shadow-none"
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            size="sm"
            type="button"
            variant="secondary"
          >
            {t('previous')}
          </Button>
          <Button
            className="!shadow-none"
            disabled={!table.getCanNextPage()}
            onClick={(e) => {
              e.stopPropagation()
              table.nextPage()
            }}
            size="sm"
            type="button"
            variant="secondary"
          >
            {t('next')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PreviewTable
