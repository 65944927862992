import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  clearAllIncrementalRefresh,
  connectDB,
  setInputData,
  setTableFile,
} from '@/features/project/projectSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'
import { IProjectInputData } from '@/types'

import { DB_CONNECT_TYPE } from '../../dataset.constant'
import { CREATE_DATASET_STEP, DATASET_TYPE } from '../datasetCreate.constant'
import { useDatasetCreateContext } from '../datasetCreate.context'
import { UseDatasetCreateProps } from './datasetCreateLayout.props'

const useFormDatasetCreate = ({
  schema,
  defaultValues,
}: UseDatasetCreateProps) => {
  const { t } = useTrans()
  const dispatch = useAppDispatch()
  const { location, navigate } = useRouter()
  const {
    pid,
    currentStep,
    dataset,
    handleNextStep,
    handlePreStep,
    handleUpdateCurrentDataset,
  } = useDatasetCreateContext()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { incrementalRefreshTables, incrementalRefresh } = useAppSelector(
    (state) => state.project,
  )
  const props: UseFormReturn<ANY, ANY, FieldValues> = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (!dataset) return

    if (currentStep === CREATE_DATASET_STEP.NAME) {
      props.setValue('name', dataset.name ?? '')
      props.setValue('description', dataset.description ?? '')
      props.setValue('type', dataset.type ?? DATASET_TYPE.SPREADSHEET)
    }

    if (currentStep === CREATE_DATASET_STEP.COLUMN) {
      const tables = dataset.meta?.tables as Array<ANY>

      props.setValue('tables', tables)
    }

    if (currentStep === CREATE_DATASET_STEP.CONNECTION) {
      const dbConfig = dataset.meta?.dbConfig as Record<string, string>
      props.setValue('dbType', dbConfig?.dbType ?? 'postgresql')
      props.setValue('host', dbConfig?.host ?? '')
      props.setValue('port', dbConfig?.port ?? '5432')
      props.setValue('dbName', dbConfig?.dbName ?? '')
      props.setValue('user', dbConfig?.user ?? '')
      props.setValue('pwd', dbConfig?.pwd ?? '')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset, currentStep])

  const onUpdateDatasetToServer = useCallback(
    (payload: Record<string, unknown>) => {
      if (!pid) {
        throw new Error('Project ID is not defined')
      }

      const _payload: Record<string, unknown> = { ...payload }

      return dispatch(setInputData(pid, _payload)).catch((error) => {
        console.error(error)
        throw error
      })
    },
    [pid],
  )

  const handleSubmitNameStep = (payload: Record<string, unknown>) => {
    handleUpdateCurrentDataset(payload)
    handleNextStep(payload.type as DATASET_TYPE)
  }

  const handleNavigateDetail = (iid: string) => {
    navigate(`${location.pathname}/${iid}`)
  }

  const handleSubmitColumnStepWithTypeSpreadsheet = async (
    payload: Record<string, unknown>,
  ) => {
    const meta = {
      ...dataset.meta,
      tables: payload.tables,
      name: dataset.name,
      description: dataset.description,
      refresh: dataset.refresh ?? false,
    }
    const promise = onUpdateDatasetToServer(meta).then((result) => {
      handleNavigateDetail((result as IProjectInputData).iid)
    })
    toast
      .promise(promise, {
        pending: t('processingData'),
        success: t('successMessage'),
        error: t('errorCreateDataset'),
      })
      .then(() => {
        dispatch(clearAllIncrementalRefresh())
        handleNextStep()
      })
      .catch((e) => {
        setIsSubmitting(false)
        toast.error(e)
      })
  }

  const handleSubmitColumnStepWithTypeDB = async (
    payload: Record<string, unknown>,
  ) => {
    const meta = {
      ...dataset.meta,
      tables: payload.tables,
      name: dataset.name,
      description: dataset.description,
      type: 'database',
      refresh: dataset.refresh ?? false,
    }
    const promise = onUpdateDatasetToServer(meta)
    toast
      .promise(promise, {
        pending: t('statusMessagesPending'),
        success: t('successMessage'),
        error: t('createDatasetError'),
      })
      .then((updatedDataset) => {
        dispatch(clearAllIncrementalRefresh())
        handleNavigateDetail((updatedDataset as IProjectInputData).iid)
        handleNextStep()
      })
      .catch((e) => {
        setIsSubmitting(false)
        toast.error(e)
      })
  }

  const handleSubmitColumnStep = async (payload: Record<string, unknown>) => {
    const invalidKeys = Object.entries(incrementalRefresh).filter(
      ([key, value]) => {
        if (!value) return false

        const tableConfig = incrementalRefreshTables[key]

        if (!tableConfig) return true

        const { id, created_at, updated_at, deleted_at } = tableConfig
        const isIdOnlyValid = id && !created_at && !updated_at && !deleted_at
        const isAppendValid = id && created_at && !updated_at && !deleted_at
        const isFullyManagedValid = id && created_at && updated_at && deleted_at

        return !isIdOnlyValid && !isAppendValid && !isFullyManagedValid
      },
    )

    if (invalidKeys.length > 0) {
      const invalidTables = invalidKeys.map(([key]) => key).join(', ')
      toast.error(`${t('incrementalError')} ${invalidTables}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      setIsSubmitting(false)
      return
    }
    switch (dataset.type) {
      case DATASET_TYPE.SPREADSHEET:
        handleSubmitColumnStepWithTypeSpreadsheet(payload)
        return

      case DATASET_TYPE.DATABASE:
        handleSubmitColumnStepWithTypeDB(payload)
        return
      default:
        throw new Error('Unknown dataset type')
    }
  }

  const handleSubmitConnectionStep = async (
    payload: Record<string, unknown>,
  ) => {
    const { type, ...restPayload } = payload
    let dbConfig = {}
    if (type === DB_CONNECT_TYPE.BIG_QUERY) {
      const {
        gcp_project_id,
        gcp_dataset_id,
        gcp_credentials = '',
      } = restPayload

      dbConfig = {
        name: dataset.name,
        description: dataset.description,
        dbType: type,
        gcp_project_id: gcp_project_id,
        gcp_dataset_id: gcp_dataset_id,
        credentials: JSON.parse(gcp_credentials as string),
      }
    } else if (type === DB_CONNECT_TYPE.SALESFORCE) {
      const { user, pwd, security_token = '' } = restPayload
      dbConfig = {
        name: dataset.name,
        description: dataset.description,
        dbType: type,
        user,
        pwd,
        security_token,
      }
    } else if (type === DB_CONNECT_TYPE.SNOWFLAKE) {
      const { account, dbName, schema, warehouse, user, pwd } = restPayload
      dbConfig = {
        name: dataset.name,
        description: dataset.description,
        dbType: type,
        account,
        dbName,
        schema,
        warehouse,
        user,
        pwd,
      }
    } else {
      // Submit for dbType is mysql or postgresql
      const { dbType, dbName, host, port, user, pwd } = restPayload
      dbConfig = {
        name: dataset.name,
        description: dataset.description,
        dbType,
        dbName,
        host,
        port,
        user,
        pwd,
      }
    }
    // CONNECT TO DATABASE
    const promise = dispatch(connectDB(pid, dbConfig))
    toast.promise(promise, {
      pending: t('processingInputData'),
      success: t('successMessage'),
      error: t('errorMessageSettingInputData'),
    })
    promise
      .then((project) => {
        handleUpdateCurrentDataset({
          meta: project as Record<string, unknown>,
        })
        handleNextStep()
        setIsSubmitting(false)
      })
      .catch((e) => {
        toast.error(e)
        setIsSubmitting(false)
      })
  }

  const uploadFileToServer = useCallback(
    async (files: FileList) => {
      if (!pid) return

      const datasetName = dataset?.name ?? ''
      const datasetDescription = dataset?.description ?? ''

      const data = new FormData()
      for (const file of Array.from(files)) data.append('files', file)
      data.append('fileName', datasetName)
      data.append('fileDescription', datasetDescription)
      const promise = dispatch(setTableFile(pid, data, () => {}))
      toast.promise(promise, {
        pending: t('processingData'),
        success: t('tableAddToProject'),
        error: t('errorLoadingFiles'),
      })

      promise
        .then((project) => {
          handleUpdateCurrentDataset({
            meta: project as Record<string, unknown>,
          })
          handleNextStep()
          setIsSubmitting(false)
        })
        .catch((e) => {
          setIsSubmitting(false)
          return e
        })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, pid],
  )

  const handleUploadWithLink = async (payload: Record<string, unknown>) => {
    const linkUrl = payload.fileLink

    if (!linkUrl) return

    const datasetName = dataset?.name ?? ''
    const datasetDescription = dataset?.description ?? ''

    const meta = {
      linkUrl,
      fileName: datasetName,
      fileDescription: datasetDescription,
    }
    const promise = dispatch(setTableFile(pid!, meta, () => {}))
    toast.promise(promise, {
      pending: t('processingData'),
      success: t('tableAddToProject'),
      error: t('errorLoadingFiles'),
    })

    promise
      .then((project) => {
        handleUpdateCurrentDataset({
          meta: project as Record<string, unknown>,
        })
        handleNextStep()
        setIsSubmitting(false)
      })
      .catch((e) => {
        setIsSubmitting(false)
        return e
      })
  }

  const onSubmit = props.handleSubmit(async (payload) => {
    switch (currentStep) {
      case CREATE_DATASET_STEP.NAME:
        handleSubmitNameStep(payload)
        return
      case CREATE_DATASET_STEP.CONNECTION:
        setIsSubmitting(true)
        await handleSubmitConnectionStep(payload)
        return
      case CREATE_DATASET_STEP.SPREADSHEET:
        setIsSubmitting(true)
        // eslint-disable-next-line no-case-declarations, no-inner-declarations
        const isValidLink = (link?: string) => {
          if (link?.startsWith('s3://')) return true
          return false
        }

        if (
          [payload.isUploadFile, isValidLink(payload.fileLink)].every((e) => !e)
        ) {
          toast.error(t('pleaseFillInTheCorrectInformation'))
          setIsSubmitting(false)
          return
        }

        if (isValidLink(payload.fileLink)) {
          await handleUploadWithLink(payload)
        } else if (payload.isUploadFile) {
          await uploadFileToServer(payload.fileToUpload)
        }
        return
      case CREATE_DATASET_STEP.COLUMN:
        setIsSubmitting(true)
        await handleSubmitColumnStep(payload)
        return
    }
  })

  return {
    form: props,
    onSubmit,
    handlePreStep,
    currentStep,
    isSubmitting,
  }
}

export default useFormDatasetCreate
