import { useEffect, useRef } from 'react'

import { CHATBOT_SOCKET_EMIT } from '@/constants/common'
import { useRouter } from '@/hooks/useRouter'
import { chatBotSocket } from '@/services/socket/chatbot.socket'
import { ANY } from '@/types'

import { useChatBotContext } from '../chatbot.context'

export const useConnectChatbot = () => {
  const { setMessages } = useChatBotContext()
  const { params } = useRouter()
  const clientId = localStorage.getItem('clientId')
  const { pid } = params

  const lastPidRef = useRef<string | null>(null)
  const hasFetchedHistoryRef = useRef(false)

  useEffect(() => {
    const listenAfterConnect = () => {
      setMessages([])
      chatBotSocket.sendMessage(
        CHATBOT_SOCKET_EMIT.CHAT_HISTORY,
        { clientId, pid },
        (pendingMessages: ANY) => {
          if (pendingMessages && pendingMessages?.length) {
            setMessages(pendingMessages)
          } else {
            setMessages([])
          }
        },
      )

      hasFetchedHistoryRef.current = true
    }

    if (lastPidRef.current !== pid) {
      lastPidRef.current = pid!
      hasFetchedHistoryRef.current = false

      if (!chatBotSocket.isConnected) {
        chatBotSocket.connect(listenAfterConnect)
      } else {
        listenAfterConnect()
      }
    }
  }, [clientId, pid, setMessages])

  return {}
}
