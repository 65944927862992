import PageHeader from '@/components/pageHeader/pageHeader'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { isAuth } from '@/helpers/auth'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

import BillingPackage from '../billingPackage/billingPackage'
import { checkoutURL } from './billingForm.constant'
import { useBillingForm, useGetTiersOptions } from './billingForm.hook'

const BillingForm = () => {
  const { t } = useTrans()
  const { params } = useRouter()
  const { email, role } = isAuth()

  const pid = params['pid']
  const {
    freeTrial,
    packageActive,
    onChoosePackage,
    onChangeFreeTrial,
    handleTeramotCheckout,
  } = useBillingForm()

  const { tiersOptions } = useGetTiersOptions()
  return (
    <PageHeader title={t('chooseYourPlan')}>
      <form action={checkoutURL} method="POST">
        <Input className="!hidden" defaultValue={pid} hidden name="pid" />
        <Input className="!hidden" defaultValue={email} hidden name="email" />
        <Input
          className="!hidden"
          hidden
          name="tierName"
          onChange={() => {
            /* do nothing */
          }}
          value={tiersOptions[packageActive].key}
        />
        {packageActive === 0 && (
          <Input
            checked={freeTrial}
            className="!hidden"
            hidden
            name="freeTrial"
            onChange={() => {
              /* do nothing */
            }}
            type="checkbox"
          />
        )}

        <div className="w-full flex flex-col">
          <div className="w-full grid grid-cols-1 gap-6 py-4 sm:grid-cols-2 md:py-8 lg:grid-cols-3">
            {tiersOptions.map((item, index) => (
              <BillingPackage
                active={index === packageActive}
                key={index}
                list={item.list}
                onChoose={() => onChoosePackage(index)}
                pricing={item.pricing}
                title={item.name}
              />
            ))}
          </div>
          <div className="flex items-center space-x-2 pb-8">
            <Checkbox
              checked={freeTrial}
              className=""
              disabled={packageActive !== 0}
              id="free-trial"
              onCheckedChange={onChangeFreeTrial}
            />
            <label
              className="text-sm cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              htmlFor="free-trial"
            >
              {t('activate7DayFreeTrial')}
            </label>
          </div>
          <div className="flex justify-end items-center gap-4">
            {(role === 'admin' || role === 'teramot') && (
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleTeramotCheckout()
                }}
                variant={'secondary'}
              >
                {t('adminTeramotCheckout')}
              </Button>
            )}
            <Button type="submit" variant="primary">
              {t('checkout')}
            </Button>
          </div>
        </div>
      </form>
    </PageHeader>
  )
}

export default BillingForm
