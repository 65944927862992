import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/lib/utils'

const buttonVariants = cva(
  // eslint-disable-next-line max-len
  'inline-flex items-center justify-center whitespace-nowrap base1 transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none',
  {
    variants: {
      variant: {
        default:
          'bg-secondary text-primary-foreground shadow hover:bg-secondary/90',
        gray: 'bg-gray-200 text-gray-800 shadow hover:bg-gray-300',
        destructive:
          'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          // eslint-disable-next-line max-len
          'border border-button-primary text-button-primary bg-background shadow-sm hover:bg-button-primary hover:text-white uppercase',
        primary:
          'bg-button-primary text-white shadow hover:bg-button-primary/90 uppercase',
        secondary:
          'bg-transparent text-button-primary border border-button-primary border-[2px] shadow ' +
          'hover:bg-button-primary/10 uppercase',
        secondaryDark:
          'bg-transparent text-button-dark border border-button-dark border-[2px] shadow ' +
          'hover:bg-button-primary/30 uppercase',
        terciary: 'text-button-primary hover:bg-button-primary/10 uppercase',
        settings:
          'bg-muted-foreground text-white shadow hover:bg-accent-1/90 !h-8 w-8 !p-0',
        sidebar: 'bg-muted-foreground text-white shadow hover:bg-accent-1/90',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-secondary underline-offset-4 hover:underline',
        link2: 'text-secondary-1 underline-offset-4 hover:underline',
        danger:
          'border border-red-600 text-red-600 shadow hover:bg-red-600 hover:text-white',
      },
      size: {
        default: 'h-9 px-5 py-2',
        sm: 'h-8 px-3 text-xs',
        lg: 'h-[3.75rem] w-[150px] px-8 py-2',
        icon: 'h-9 w-9',
      },
      borderRadius: {
        reset: 'rounded-none',
        default: 'rounded-[100px]',
        large: 'rounded-lg',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      borderRadius: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, borderRadius, asChild = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, borderRadius, className }),
          { 'text-[#A39C9C]': props.disabled && variant === 'terciary' },
          { 'text-[#A39C9C]': props.disabled && variant === 'ghost' },
          {
            'text-[#7C7C7C] bg-[#A39C9C4D] border-none shadow-none':
              props.disabled &&
              (variant === 'primary' ||
                variant === 'default' ||
                variant === 'secondary'),
          },
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
