import { TrashIcon } from '@radix-ui/react-icons'
import { HiOutlinePencilAlt } from 'react-icons/hi'

import IconTooltip from '@/components/iconTooltip'
import { onFocusInputById } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

import useQuestionItem from './questionItem.hook'
import QuestionItemForm from './questionItemForm/questionItemForm'

interface IProps {
  title?: string
  content: string
  pid: string
  index: number
  onDelete: ANY
}
const QuestionItem = (props: IProps) => {
  const { t } = useTrans()
  const { isEdit, toggleEdit, handDeleteQuestion } = useQuestionItem({
    onDelete: props.onDelete,
  })

  return (
    <div className="w-full px-8 py-4 relative shadow-md rounded-md border">
      {!isEdit && (
        <div className="flex justify-end">
          <IconTooltip
            icon={
              <HiOutlinePencilAlt
                className="cursor-pointer absolute top-4 right-8"
                fontWeight={700}
                onClick={() => {
                  toggleEdit()
                  onFocusInputById(`focus-${props.title}`)
                }}
              />
            }
            message={t('iconUpdateQuestion')}
          />
          <IconTooltip
            icon={
              <TrashIcon
                className="cursor-pointer absolute top-11 right-8"
                fontWeight={700}
                onClick={handDeleteQuestion}
              />
            }
            message={t('iconDeleteQuestion')}
          />
        </div>
      )}
      <div className="w-full pr-6">
        {props.title && <p className="caption2 mb-1">{props.title}</p>}
        {!isEdit && props.content && (
          <p className="text-sm break-words">{props.content}</p>
        )}
        {isEdit && (
          <QuestionItemForm
            IdFocus={`focus-${props.title}`}
            defaultValues={{
              pid: props.pid,
              question: props.content,
              index: props.index,
            }}
            toggleEdit={toggleEdit}
          />
        )}
      </div>
    </div>
  )
}

export default QuestionItem
