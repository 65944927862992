import { cn } from '@/lib/utils'

import { SidebarBodyProps, SidebarItemProps } from './sidebarBody.props'

const SideBarItem = (props: SidebarItemProps) => {
  const { icon, label, active, labelClassName, iconClassName, isInfoItem } =
    props

  const classContainer = cn(
    'w-full px-8 py-2 flex gap-4 cursor-pointer items-center',
    {
      'bg-[#2D43E0]': active,
    },
  )

  const classText = cn(
    'h5 text-[#BDC5E9]',
    {
      'text-[14px] font-[500]': isInfoItem,
      'font-bold text-white': active,
    },
    labelClassName,
  )

  const classIcon = cn(
    'text-[#5471F0] align-middle',
    {
      '!text-white': active,
      'text-[#91A5FF]': isInfoItem,
    },
    iconClassName,
  )

  return (
    <div className={classContainer}>
      {icon && <div className={classIcon}>{icon}</div>}
      <p className={classText}>{label}</p>
    </div>
  )
}

const SideBarBody = (props: SidebarBodyProps) => {
  const { children } = props
  return <div className="w-full mt-[12px]">{children}</div>
}

SideBarBody.displayName = 'SideBarBody'

SideBarBody.Item = SideBarItem
export default SideBarBody
